import { takeEvery, call, put } from 'redux-saga/effects';
import axios from 'axios';

function* updateSliderType(action) {
    try {
        yield call(axios.put, `${process.env.REACT_APP_API}/sliderType/${action.body.slidertypeId}`, { ...action.body }, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('access_token')}`,
            },
        });
        const { data } = yield call(axios.get, `${ process.env.REACT_APP_API }/sliderType`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('access_token')}`,
            },
        });
        yield put({ type: 'GET_ALL_SLIDER_TYPES', sliderTypes: data });
    } catch (e) {
        yield put({ type: 'UPDATE_SLIDER_TYPES', error: true });
    }
}

export default function* watchUPDATEMESSAGE() {
    yield takeEvery('UPDATE_SLIDER_TYPE', updateSliderType);
}
