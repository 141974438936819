import { takeEvery, call, put } from 'redux-saga/effects';
import axios from 'axios';

function* fetchSubService(actions)
{
    try
    {
        const { data } = yield call(axios.get, `${ process.env.REACT_APP_API }/subService/${ actions.body.subServiceId }`, {
            headers: {
                Authorization: `Bearer ${ localStorage.getItem('access_token') }`,
            },
        });

        yield put({ type: 'GET_SUB_SERVICES', subService: data });
    } catch (e)
    {
        yield put({ type: 'SUB_SERVICE_ERROR', message: e, error: true });
    }
}

export default function* watchUPDATEMESSAGE()
{
    yield takeEvery('GET_SUB_SERVICE', fetchSubService);
}
