const defaultState = {
    roleList: [],
    roleResponse: {},
    error: false,
};

export default function roleReducer(state = defaultState, action) {
    switch (action.type) {
        case 'GET_ALL_ROLES': {
            return {
                ...state,
                error: action.error,
                roleList: action.roles,
                updateState: true,
            };
        }
        case 'GET_ALL_ROLEPAGES': {
            return {
                ...state,
                error: action.error,
                roleList: action.roles,
                updateState: true,
            };
        }
        case 'ADD_ROLES': {
            return {
                ...state,
                error: action.error,
                roleResponse: action.roleResponse,
                addRoleState: true,
            };
        }
        case 'GET_ROLES': {
            return {
                ...state,
                error: action.error,
                role: action.role,
                updateGetState: true,
            };
        }
        case 'UPDATE_ROLES': {
            return {
                ...state,
                error: action.error,
                updateResponse: action.updateResponse,
                updateRoleState: true,
            };
        }
        case 'UPDATE_ROLE_NAMES': {
            return {
                ...state,
                error: action.error,
                updateResponse: action.updateResponse,
                updateRoleState: true,
            };
        }
        case 'REMOVE_SCOPE_ROLES': {
            return {
                ...state,
                error: action.error,
                updateResponse: action.updateResponse,
                updateRoleState: true,
            };
        }
        case 'DELETE_ROLES': {
            return {
                ...state,
                error: action.error,
                updateRoleState: true,
            };
        }
        case 'UNSET_ROLE_STATE': {
            return {
                ...state,
                updateState: false,
            };
        }
        case 'UNSET_GETROLE_STATE': {
            return {
                ...state,
                updateGetState: false,
            };
        }
        default: {
            return state;
        }
    }
}
