import { takeEvery, call, put } from 'redux-saga/effects';
import axios from 'axios';

function* fetchRole(actions)
{
    try
    {
        const { data } = yield call(axios.get, `${ process.env.REACT_APP_API }/role/${ actions.body.roleId }`, {
            headers: {
                Authorization: `Bearer ${ localStorage.getItem('access_token') }`,
            },
        });
        yield put({ type: 'GET_ROLES', role: data });
    } catch (e)
    {
        yield put({ type: 'GET_ROLES', error: true });
    }
}

export default function* watchUPDATEMESSAGE()
{
    yield takeEvery('GET_ROLE', fetchRole);
}
