import { takeEvery, call, put } from 'redux-saga/effects';
import axios from 'axios';

function* fetchFile(actions) {
    try {
        const { data } = yield call(axios.get, `${ process.env.REACT_APP_API }/files/${actions.body.filesId}`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('access_token')}`,
            },
        });
        
        yield put({ type: 'GET_FILES', files: data });
    } catch (e) {
        yield put({ type: 'FILE_ERROR', message: e, error: true });
    }
}

export default function* watchUPDATEMESSAGE() {
    yield takeEvery('GET_FILE', fetchFile);
}
