const defaultState = {
    notificationList: [],
    notificationResponse: {},
    updateResponse: {},
    errorResponse: {},
    error: false,

};

export default function notificationReducer(state = defaultState, action) {
    switch (action.type) {
        case 'GET_ALL_NOTIFICATIONS': {
            return {
                ...state,
                error: action.error,
                notificationList: action.notifications,
                updateState: true,
            };
        }
        case 'GET_NOTIFICATIONS': {
            return {
                ...state,
                error: action.error,
                notificationResponse: action.notification,
                updateGetState: true,
            };
        }
        case 'ADD_NOTIFICATIONS': {
            return {
                ...state,
                error: action.error,
                notificationResponse: action.notificationResponse,
            };
        }
        case 'UPDATE_NOTIFICATIONS': {
            return {
                ...state,
                error: action.error,
                updateResponse: action.updateResponse,
                updateRoleState: true,
            };
        }
        case 'DELETE_NOTIFICATIONS': {
            return {
                ...state,
                error: action.error,
                deleteResponse: action.deleteResponse,
                deleteState: true,
            };
        }
        case 'UNSET_NOTIFICATION_STATE': {
            return {
                ...state,
                updateState: false,
            };
        }
        case 'UNSET_GETNOTIFICATION_STATE': {
            return {
                ...state,
                updateGetState: false,
            };
        }
        case 'NOTIFICATION_ERROR': {
            return {
                ...state,
                errorResponse: action.message,
            };
        }
        default: {
            return state;
        }
    }
}
