import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import createSagaMiddleware from 'redux-saga';
import { persistStore } from "redux-persist";

import reducer from './Reducers';
import sagas from './Sagas';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const sagaMiddleware = createSagaMiddleware();

const store = createStore(
  reducer, 
  composeEnhancers(applyMiddleware(thunk, sagaMiddleware))
);

export const persistor = persistStore(store);

sagaMiddleware.run(sagas);

export default store;
