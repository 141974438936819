import { takeEvery, call, put } from 'redux-saga/effects';
import axios from 'axios';

function* fetchAllFeedback(action) {
    try {
        const { data } = yield call(axios.post, `${process.env.REACT_APP_API}/feedback/${action.body.page}/${action.body.limit}`, { ...action.body }, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('access_token')}`,
            },
        });

        yield put({ type: 'GET_ALL_FEEDBACKS', feedbacks: data });
    } catch (e) {
        yield put({ type: 'FEEDBACK_ERROR', message: e, error: true });
    }
}

export default function* watchUPDATEMESSAGE() {
    yield takeEvery('GET_ALL_FEEDBACK', fetchAllFeedback);
}
