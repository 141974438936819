const defaultState = {
    vehicleMakeList: [],
    vehicleMakeResponse: {},
    updateResponse: {},
    errorResponse: {},
    error: false,
};

export default function vehicleMakeReducer(state = defaultState, action) {
    switch (action.type) {
        case 'GET_ALL_VEHICLEMAKES': {
            return {
                ...state,
                error: action.error,
                vehicleMakeList: action.vehicleMakes,
                updateState: true,
            };
        }
        case 'GET_ALL_VEHICLEMAKEPAGES': {
            return {
                ...state,
                error: action.error,
                vehicleMakeList: action.vehicleMakes,
                updateState: true,
            };
        }
        case 'GET_VEHICLEMAKES': {
            return {
                ...state,
                error: action.error,
                vehicleMakeResponse: action.vehicleMake,
                updateGetState: true,
            };
        }
        case 'ADD_VEHICLEMAKES': {
            return {
                ...state,
                error: action.error,
                vehicleMakeResponse: action.vehicleMakeResponse,
            };
        }
        case 'UPDATE_VEHICLEMAKES': {
            return {
                ...state,
                error: action.error,
                updateResponse: action.updateResponse,
                updateRoleState: true,
            };
        }
        case 'DELETE_VEHICLEMAKES': {
            return {
                ...state,
                error: action.error,
                deleteResponse: action.deleteResponse,
                deleteState: true,
            };
        }
        case 'UNSET_VEHICLEMAKE_STATE': {
            return {
                ...state,
                updateState: false,
            };
        }
        case 'UNSET_GETVEHICLEMAKE_STATE': {
            return {
                ...state,
                updateGetState: false,
            };
        }
        case 'VEHICLEMAKE_ERROR': {
            return {
                ...state,
                errorResponse: action.message,
            };
        }
        default: {
            return state;
        }
    }
}
