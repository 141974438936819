import { takeEvery, call, put } from 'redux-saga/effects';
import axios from 'axios';

function* fetchAllSubService(action)
{
    try
    {
        const { data } = yield call(axios.get, `${ process.env.REACT_APP_API }/subService`, {
            headers: {
                Authorization: `Bearer ${ localStorage.getItem('access_token') }`,
            },
        });

        yield put({ type: 'GET_ALL_SUB_SERVICES', subServices: data });
    } catch (e)
    {
        yield put({ type: 'SUB_SERVICE_ERROR', message: e, error: true });
    }
}

export default function* watchUPDATEMESSAGE()
{
    yield takeEvery('GET_ALL_SUB_SERVICE', fetchAllSubService);
}
