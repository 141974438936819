import { takeEvery, call, put } from 'redux-saga/effects';
import axios from 'axios';

function* postVehicleModel(action) {
    try {

        yield call(axios.post, `${process.env.REACT_APP_API}/vehicleModel`, { ...action.body }, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('access_token')}`,
            },
        });
        const { data } = yield call(axios.post, `${process.env.REACT_APP_API}/vehicleModel/1/10`, {...action.body},{
            headers: {
                Authorization: `Bearer ${localStorage.getItem('access_token')}`,
            },
        });

        yield put({ type: 'GET_ALL_VEHICLEMODELPAGES', vehicleModels: data });
    } catch (e) {
        yield put({ type: 'ADD_VEHICLEMODELS', error: true });
    }
}

export default function* watchUPDATEMESSAGE() {
    yield takeEvery('ADD_VEHICLEMODEL', postVehicleModel);
}
