import { takeEvery, call, put } from 'redux-saga/effects';
import axios from 'axios';

function* deleteSlider(action) {
    try {
        yield call(axios.delete, `${process.env.REACT_APP_API}/slider/${action.body.sliderId}`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('access_token')}`,
            },
        });
        const slider = yield call(axios.post, `${process.env.REACT_APP_API}/slider/1/10`, { ...action.body }, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('access_token')}`,
            },
        });
        yield put({ type: 'GET_ALL_SLIDERS', sliders: slider.data });
    } catch (e) {
        yield put({ type: 'DELETE_SLIDERS', error: true });
    }
}

export default function* watchUPDATEMESSAGE() {
    yield takeEvery('DELETE_SLIDER', deleteSlider);
}
