import { takeEvery, call, put } from 'redux-saga/effects';
import axios from 'axios';

function* updateUser(action) {
    try {
        yield call(axios.post, `${ process.env.REACT_APP_API }/country/update`, { ...action.body }, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('access_token')}`,
            },
        });
        const country = yield call(axios.get, `${ process.env.REACT_APP_API }/country/${ action.body.countryId }`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('access_token')}`,
            },
        });
        yield put({ type: 'GET_COUNTRYS', country: country.data });
    } catch (e) {
        yield put({ type: 'UPDATE_COUNTRYS', error: true });
    }
}

export default function* watchUPDATEMESSAGE() {
    yield takeEvery('UPDATE_COUNTRY', updateUser);
}
