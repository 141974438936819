const defaultState = {
  countryList: [],
  countryResponse: {},
  updateResponse: {},
  errorResponse: {},
  error: false,
};

export default function countryReducer(state = defaultState, action) {
  switch (action.type) {
      case 'GET_ALL_COUNTRYS': {
          return {
              ...state,
              error: action.error,
              countryList: action.countrys,
              updateState: true,
          };
      }
      case 'GET_COUNTRYS': {
          return {
              ...state,
              error: action.error,
              country: action.country,
              updateGetState: true,
          };
      }
      case 'ADD_COUNTRYS': {
          return {
              ...state,
              error: action.error,
              countryResponse: action.countryResponse,
          };
      }
      case 'UPDATE_COUNTRYS': {
          return {
              ...state,
              error: action.error,
              updateResponse: action.updateResponse,
              updateRoleState: true,
          };
      }
      case 'UNSET_COUNTRY_STATE': {
          return {
              ...state,
              updateState: false,
          };
      }
      case 'UNSET_GETCOUNTRY_STATE': {
          return {
              ...state,
              updateGetState: false,
          };
      }
      case 'COUNTRY_ERROR': {
          return {
              ...state,
              errorResponse: action.message,
          };
      }
      default: {
          return state;
      }
  }
}
