import { takeEvery, call, put } from 'redux-saga/effects';
import axios from 'axios';

function* postFile(action) {
    try {
        let formData = new FormData();
        formData.append('file', action.body.avatar);
        formData.append('description', action.body.description);

               yield call(axios.post, `${process.env.REACT_APP_API}/files`,formData , {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('access_token')}`,
            },
        });
        const file = yield call(axios.post, `${process.env.REACT_APP_API}/files/1/10`, { ...action.body }, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('access_token')}`,
            },
        });
        yield put({ type: 'GET_ALL_FILES', files: file.data });
    } catch (e) {
        yield put({ type: 'ADD_FILES', error: true });
    }
}

export default function* watchUPDATEMESSAGE() {
    yield takeEvery('ADD_FILE', postFile);
}
