const defaultState = {
    feedbackList: [],
    updateResponse: {},
    errorResponse: {},
    error: false,

};

export default function feedbackReducer(state = defaultState, action) {
    switch (action.type) {
        case 'GET_ALL_FEEDBACKS': {
            return {
                ...state,
                error: action.error,
                feedbackList: action.feedbacks,
                updateState: true,
            };
        }
        case 'UNSET_FEEDBACK_STATE': {
            return {
                ...state,
                updateState: false,
            };
        }
        case 'FEEDBACK_ERROR': {
            return {
                ...state,
                errorResponse: action.message,
            };
        }
        default: {
            return state;
        }
    }
}
