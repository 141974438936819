import { takeEvery, call, put } from 'redux-saga/effects';
import axios from 'axios';

function* postVehicleLevel(action) {
    try {

        yield call(axios.post, `${process.env.REACT_APP_API}/vehicleLevels`, { ...action.body }, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('access_token')}`,
            },
        });
        const { data } = yield call(axios.get, `${process.env.REACT_APP_API}/vehicleLevels`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('access_token')}`,
            },
        });
        yield put({ type: 'GET_ALL_VEHICLELEVELS', vehicleLevels: data });
    } catch (e) {
        yield put({ type: 'ADD_VEHICLELEVELS', error: true });
    }
}

export default function* watchUPDATEMESSAGE() {
    yield takeEvery('ADD_VEHICLELEVEL', postVehicleLevel);
}
