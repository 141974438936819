const defaultState = {
    sliderList: [],
    sliderResponse: {},
    updateResponse: {},
    errorResponse: {},
    error: false,

};

export default function sliderReducer(state = defaultState, action) {
    switch (action.type) {
        case 'GET_ALL_SLIDERS': {
            return {
                ...state,
                error: action.error,
                sliderList: action.sliders,
                updateState: true,
            };
        }
        case 'GET_SLIDERS': {
            return {
                ...state,
                error: action.error,
                sliderResponse: action.sliders,
                updateGetState: true,
            };
        }
        case 'ADD_SLIDERS': {
            return {
                ...state,
                error: action.error,
                sliderResponse: action.sliderResponse,
            };
        }
        case 'UPDATE_SLIDERS': {
            return {
                ...state,
                error: action.error,
                updateResponse: action.updateResponse,
                updateRoleState: true,
            };
        }
        case 'DELETE_SLIDERS': {
            return {
                ...state,
                error: action.error,
                deleteResponse: action.deleteResponse,
                deleteState: true,
            };
        }
        case 'UNSET_SLIDER_STATE': {
            return {
                ...state,
                updateState: false,
            };
        }
        case 'UNSET_GETSLIDER_STATE': {
            return {
                ...state,
                updateGetState: false,
            };
        }
        case 'SLIDER_ERROR': {
            return {
                ...state,
                errorResponse: action.message,
            };
        }
        default: {
            return state;
        }
    }
}
