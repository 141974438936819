/**
 * Create React App entry point. This and `public/index.html` files can not be
 * changed or moved.
 */
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom";

import store, { persistor } from './app/ReduxStore'
import App from "./App";
import "./index.scss"; // Standard version
// import "./sass/style.react.rtl.css"; // RTL version
import "socicon/css/socicon.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "./_metronic/_assets/plugins/line-awesome/css/line-awesome.css";
import "./_metronic/_assets/plugins/flaticon/flaticon.css";
import "./_metronic/_assets/plugins/flaticon2/flaticon.css";

ReactDOM.render(
  <App
    store={ store }
    persistor={ persistor }
  />,
  document.getElementById("root")
);

// Service Worker 
if ('serviceWorker' in navigator) {
  // Register service worker
  navigator.serviceWorker.register('/OneSignalSDKWorker.js').then(function (reg) {
    console.log("SW registration succeeded. Scope is " + reg.scope);
  }).catch(function (err) {
    console.error("SW registration failed with error " + err);
  });
}

// var OneSignal = window.OneSignal || [];
// OneSignal.push(function () {
//   OneSignal.init({
//     appId: "a840d653-a6b8-4f0d-ad8d-7d8e89b92804",
//     autoRegister: true,
//     notifyButton: {
//       enable: true,
//     },
//   });
//   getUserDetails();
//   OneSignal.showNativePrompt();
//   async function getUserDetails() {
//     const userId = await OneSignal.getUserId();
//     // console.log(userId)
//     localStorage.setItem('notificationID', userId)
//   }
// });

