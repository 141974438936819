import { takeEvery, call, put } from 'redux-saga/effects';
import axios from 'axios';

function* updateBookingStatus(action) {
    try {
        yield call(axios.put, `${process.env.REACT_APP_API}/transactionHeader/extendTime/${action.body.bookingId}`, { ...action.body }, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('access_token')}`,
            },
        });
        // const { data } = yield call(axios.post, `${process.env.REACT_APP_API}/transactionHeader/allWorkShopJobCard/1/10`, {
        //     headers: {
        //         Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        //     },
        // });
        // yield put({ type: 'GET_ALL_WORKSHOP_JOB_CARDS', jobcards: data });
    } catch (e) {
        yield put({ type: 'WORKSHOP_JOB_CARD_ERROR', error: true });
    }
}

export default function* watchUPDATEMESSAGE() {
    yield takeEvery('EXTEND_WORK_TIME', updateBookingStatus);
}