const defaultState = {
    vehicleLevelList: [],
    vehicleLevelResponse: {},
    updateResponse: {},
    errorResponse: {},
    error: false,
};

export default function vehicleLevelReducer(state = defaultState, action) {
    switch (action.type) {
        case 'GET_ALL_VEHICLELEVELS': {
            return {
                ...state,
                error: action.error,
                vehicleLevelList: action.vehicleLevels,
                updateState: true,
            };
        }
        case 'GET_VEHICLELEVELS': {
            return {
                ...state,
                error: action.error,
                vehicleLevelResponse: action.vehicleLevel,
                updateGetState: true,
            };
        }
        case 'ADD_VEHICLELEVELS': {
            return {
                ...state,
                error: action.error,
                vehicleLevelResponse: action.vehicleLevelResponse,
            };
        }
        case 'UPDATE_VEHICLELEVELS': {
            return {
                ...state,
                error: action.error,
                updateResponse: action.updateResponse,
                updateRoleState: true,
            };
        }
        case 'DELETE_VEHICLELEVELS': {
            return {
                ...state,
                error: action.error,
                deleteResponse: action.deleteResponse,
                deleteState: true,
            };
        }
        case 'UNSET_VEHICLELEVEL_STATE': {
            return {
                ...state,
                updateState: false,
            };
        }
        case 'UNSET_GETVEHICLELEVEL_STATE': {
            return {
                ...state,
                updateGetState: false,
            };
        }
        case 'VEHICLELEVEL_ERROR': {
            return {
                ...state,
                errorResponse: action.message,
            };
        }
        default: {
            return state;
        }
    }
}
