import { takeEvery, call, put } from 'redux-saga/effects';
import axios from 'axios';

function* deleteRole(action) {
    try {
        yield call(axios.delete, `${process.env.REACT_APP_API}/role/${action.body.roleId}`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('access_token')}`,
            },
        });
        const { data } = yield call(axios.post, `${process.env.REACT_APP_API}/role/1/10`, { ...action.body }, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('access_token')}`,
            },
        });
        yield put({ type: 'GET_ALL_ROLEPAGES', roles: data });
    } catch (e) {
        yield put({ type: 'DELETE_ROLES', error: true });
    }
}

export default function* watchUPDATEMESSAGE() {
    yield takeEvery('DELETE_ROLE', deleteRole);
}
