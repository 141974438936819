import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

const defaultState = {
  message: '',
  branch: '',
  user: '',
  error: false,
};

const reducer = persistReducer(
  { storage, key: "uDaTa", whitelist: ["user"] },
  (state = defaultState, action) => {
    switch (action.type) {
      case 'GET_MES': {
        return {
          ...state,
          message: action.message,
          user: action.user,
          error: action.error,
          updateState: true,
        };
      }
      case 'GET_ME_LOGOUT': {
        storage.removeItem('uDaTa')
        return {
          ...state,
          user: '',
        };
      }
      case 'UNSET_GET_ME_STATE': {
        return {
          ...state,
          updateState: false,
        };
      }
      default: {
        return state;
      }
    }
  }
);

export default reducer;