import React, { Suspense, lazy } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import Dashboard from "./Dashboard";
import { LayoutSplashScreen } from "../../../_metronic";

const ReactBootstrapPage = lazy(() =>
  import("./react-bootstrap/ReactBootstrapPage")
);

const MasterPages = lazy(() =>
  import("./masters")
);

const UserPages = lazy(() =>
  import("./users")
);

const ProfilePage = lazy(() =>
  import("./Profile")
);
const ChangePassword = lazy(() =>
  import("./ChangePassword")
);



export default function HomePage() {

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {
          /* Redirect from root URL to /dashboard. */
          <Redirect exact from="/" to="/dashboard" />
        }
        <Route path="/dashboard" component={Dashboard} />
        <Route path="/master" component={MasterPages} />
        <Route path="/react-bootstrap" component={ReactBootstrapPage} />
        <Route path="/user" component={UserPages} />
        <Route path="/profile" component={ProfilePage} />
        <Route path="/changepassword" component={ChangePassword} />
        <Redirect to="/error/error-v1" />

      </Switch>
    </Suspense>
  );
}
