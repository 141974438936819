const defaultState = {
  locationList: [],
  locationResponse: {},
  updateResponse: {},
  errorResponse: {},
  error: false,
};

export default function locationReducer(state = defaultState, action) {
  switch (action.type) {
      case 'GET_ALL_LOCATIONS': {
          return {
              ...state,
              error: action.error,
              locationList: action.locations,
              updateState: true,
          };
      }
      case 'GET_LOCATIONS': {
          return {
              ...state,
              error: action.error,
              location: action.location,
              updateGetState: true,
          };
      }
      case 'ADD_LOCATIONS': {
          return {
              ...state,
              error: action.error,
              locationResponse: action.locationResponse,
          };
      }
      case 'UPDATE_LOCATIONS': {
          return {
              ...state,
              error: action.error,
              updateResponse: action.updateResponse,
              updateRoleState: true,
          };
      }
      case 'UNSET_LOCATION_STATE': {
          return {
              ...state,
              updateState: false,
          };
      }
      case 'UNSET_GETLOCATION_STATE': {
          return {
              ...state,
              updateGetState: false,
          };
      }
      case 'LOCATION_ERROR': {
          return {
              ...state,
              errorResponse: action.message,
          };
      }
      default: {
          return state;
      }
  }
}
