import { takeEvery, call, put } from 'redux-saga/effects';
import axios from 'axios';

function* deleteNews(action) {
    try {
        yield call(axios.delete, `${process.env.REACT_APP_API}/news/${action.body.newsId}`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('access_token')}`,
            },
        });
        const news = yield call(axios.post, `${process.env.REACT_APP_API}/news/1/10`, { ...action.body }, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('access_token')}`,
            },
        });
        yield put({ type: 'GET_ALL_NEWSS', newss: news.data });
    } catch (e) {
        yield put({ type: 'DELETE_NEWSS', error: true });
    }
}

export default function* watchUPDATEMESSAGE() {
    yield takeEvery('DELETE_NEWS', deleteNews);
}
