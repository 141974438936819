import { takeEvery, call, put } from 'redux-saga/effects';
import axios from 'axios';

function* updateScope(action) {
    try {
        yield call(axios.put, `${process.env.REACT_APP_API}/scope/${action.body.scopeId}`, { ...action.body }, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('access_token')}`,
            },
        });
        const scope = yield call(axios.get, `${process.env.REACT_APP_API}/scope`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('access_token')}`,
            },
        });
        yield put({ type: 'GET_ALL_SCOPES', scopes: scope.data });
    } catch (e) {
        yield put({ type: 'UPDATE_SCOPES', error: true });
    }
}

export default function* watchUPDATEMESSAGE() {
    yield takeEvery('UPDATE_SCOPE', updateScope);
}
