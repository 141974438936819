import { takeEvery, call, put } from 'redux-saga/effects';
import axios from 'axios';

function* postCounrty(action) {
    try {
        yield call(axios.post, `${ process.env.REACT_APP_API }/location`, { ...action.body }, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('access_token')}`,
            },
        });
        const location = yield call(axios.get, `${ process.env.REACT_APP_API }/location`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('access_token')}`,
            },
        });
        yield put({ type: 'GET_ALL_LOCATIONS', locations: location.data });
    } catch (e) {
        yield put({ type: 'ADD_LOCATIONS', error: true });
    }
}

export default function* watchUPDATEMESSAGE() {
    yield takeEvery('ADD_LOCATION', postCounrty);
}
