import { takeEvery, call, put } from 'redux-saga/effects';
import axios from 'axios';

function* fetchUserType(actions) {
    try {
        const { data } = yield call(axios.get, `${process.env.REACT_APP_API}/userType/${actions.body.userTypeId}`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('access_token')}`,
            },
        });
        yield put({ type: 'GET_USERTYPES', userType: data });
    } catch (e) {
        yield put({ type: 'USERTYPE_ERROR', message: e, error: true });
    }
}

export default function* watchUPDATEMESSAGE() {
    yield takeEvery('GET_USERTYPE', fetchUserType);
}
