const defaultState = {
    newsList: [],
    newsResponse: {},
    updateResponse: {},
    errorResponse: {},
    error: false,

};

export default function newsReducer(state = defaultState, action) {
    switch (action.type) {
        case 'GET_ALL_NEWSS': {
            return {
                ...state,
                error: action.error,
                newsList: action.newss,
                updateState: true,
            };
        }
        case 'GET_NEWSS': {
            return {
                ...state,
                error: action.error,
                newsResponse: action.newss,
                updateGetState: true,
            };
        }
        case 'ADD_NEWSS': {
            return {
                ...state,
                error: action.error,
                newsResponse: action.newsResponse,
            };
        }
        case 'UPDATE_NEWSS': {
            return {
                ...state,
                error: action.error,
                updateResponse: action.updateResponse,
                updateRoleState: true,
            };
        }
        case 'DELETE_NEWSS': {
            return {
                ...state,
                error: action.error,
                deleteResponse: action.deleteResponse,
                deleteState: true,
            };
        }
        case 'UNSET_NEWS_STATE': {
            return {
                ...state,
                updateState: false,
            };
        }
        case 'UNSET_GETNEWS_STATE': {
            return {
                ...state,
                updateGetState: false,
            };
        }
        case 'NEWS_ERROR': {
            return {
                ...state,
                errorResponse: action.message,
            };
        }
        default: {
            return state;
        }
    }
}
