import GetAllRole from './GetAllRole';
import PostRole from './PostRole';
import GetRole from './GetRole';
import UpdateRole from './UpdateRole';
import UpdateRoleName from './UpdateRoleName';
import RemoveScopeFromRole from './RemoveScopeFromRole';
import DeleteRole from './DeleteRole';
import GetAllRolePage from './GetAllRolePage';


export default [GetAllRole, DeleteRole, PostRole, GetRole, UpdateRoleName, RemoveScopeFromRole, UpdateRole, GetAllRolePage];
