import { takeEvery, call, put } from 'redux-saga/effects';
import axios from 'axios';

function* customFilter(action) {
    const { userType, limit, page } = action.body;
    try {
        const { data } = yield call(axios.post, `${process.env.REACT_APP_API}/user/customFilter/${userType}/${page}/${limit}`, { ...action.body.filter }, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('access_token')}`,
            },
        });
        yield put({ type: 'CUSTOM_FILTER_USERS', users: data });
    } catch (e) {
        yield put({ type: 'USER_ERROR', message: e, error: true });
    }
}

export default function* watchUPDATEMESSAGE() {
    yield takeEvery('CUSTOM_FILTER_USER', customFilter);
}
