import { takeEvery, call, put } from 'redux-saga/effects';
import axios from 'axios';

function* fetchAllSpare(action) {
    try {
        const { data } = yield call(axios.post, `${ process.env.REACT_APP_API }/spares/${action.body.page}/${action.body.limit}`, { ...action.body }, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('access_token')}`,
            },
        });
        yield put({ type: 'GET_ALL_SPARES', spares: data });
    } catch (e) {
        yield put({ type: 'SPARE_ERROR', message: e, error: true });
    }
}

export default function* watchUPDATEMESSAGE() {
    yield takeEvery('GET_ALL_SPARE', fetchAllSpare);
}
