import { takeEvery, call, put } from 'redux-saga/effects';
import axios from 'axios';

function* deleteSubscription(action) {
    try {
        yield call(axios.delete, `${process.env.REACT_APP_API}/subscriptions/${action.body.subscriptionsId}`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('access_token')}`,
            },
        });
        const { data } = yield call(axios.get, `${process.env.REACT_APP_API}/subscriptions`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('access_token')}`,
            },
        });

        yield put({ type: 'GET_ALL_SUBSCRIPTIONS', subscriptions: data });
    } catch (e) {
        yield put({ type: 'DELETE_SUBSCRIPTION', error: true });
    }
}

export default function* watchUPDATEMESSAGE() {
    yield takeEvery('DELETE_SUBSCRIPTION', deleteSubscription);
}
