export default {
  header: {
    self: {},
    items: [
      // {
      //   title: "Dashboards",
      //   root: true,
      //   alignment: "right",
      //   page: "dashboard",
      //   translate: "MENU.DASHBOARD"
      // }
    ]
  },
  aside: {
    self: {},
    items: [
      // {
      //   section : "Exit1"
      // },
      {
        title: "Dashboard",
        root: true,
        icon: "flaticon2-architecture-and-city",
        page: "dashboard",
        bullet: "dot"
      },
      { section: "Exit1" },
      {
        title: "Roles",
        root: true,
        icon: "flaticon2-lock",
        submenu: [
          {
            title: "Roles",
            page: "master/role",
          },
          {
            title: "Scopes",
            page: "master/scope"
          },
        ]
      },
      {
        title: "Users",
        root: true,
        icon: "flaticon2-group",
        submenu: [
          {
            title: "Company User",
            page: "user/companyUser"
          },
        ]
      },
      {
        title: "Reception and Workshop",
        root: true,
        icon: "flaticon2-layers",
        submenu: [
          // {
          //   title: "Job Cards",
          //   page: "master/admin/jobcard"
          // },
          {
            title: "Today Job Cards",
            page: "master/admin/monitorjobcard"
          },
          {
            title: "Cancelled Job Cards",
            page: "master/admin/canceljobcard"
          },
          {
            title: "Appointments",
            page: "master/admin/calendar"
          },
          {
            title: "Customer Details",
            page: "master/admin/customer"
          },
          {
            title: "Spare parts lists",
            page: "master/admin/spareparts"
          },
        ]
      },
      {
        title: "Reception",
        root: true,
        icon: "flaticon2-list-2",
        submenu: [
          {
            title: "Job Cards",
            page: "master/reception/jobcard"
          },
          {
            title: "Today Job Cards",
            page: "master/reception/monitorjobcard"
          },
          {
            title: "Cancelled Job Cards",
            page: "master/reception/canceljobcard"
          },
          {
            title: "Appointments",
            page: "master/reception/calendar"
          },
          {
            title: "Customer Details",
            page: "master/reception/customer"
          },
          {
            title: "Cars",
            page: "master/reception/cars"
          },
        ]
      },
      {
        title: "Workshop",
        root: true,
        icon: "flaticon2-menu-1",
        submenu: [
          {
            title: "Job Cards",
            page: "master/workshop/jobcard"
          },
          {
            title: "Today Job Cards",
            page: "master/workshop/monitorjobcard"
          },
          {
            title: "Appointments",
            page: "master/workshop/calendar"
          },
          // {
          //   title: "Customer Data",
          //   page: "master/workshop/customer"
          // },
          // {
          //   title: "Customer Details",
          //   page: "master/workshop/customer"
          // },
          {
            title: "Spare Parts List",
            page: "master/workshop/spareparts"
          },
        ]
      },
      {
        title: "Booking Engine",
        root: true,
        icon: "flaticon2-menu-2",
        submenu: [
          {
            title: "All Bookings",
            page: "master/admin/jobcard"
          },
        ]
      },
      {
        title: "Operations",
        root: true,
        icon: "flaticon2-copy",
        submenu: [
          {
            title: "Cars",
            page: "master/admin/cars"
          },
          {
            title: "Services",
            page: "master/services"
          },
          {
            title: "Sub Service",
            page: "master/subService"
          },
          {
            title: "Feedback",
            page: "master/feedback"
          },
          {
            title: "Marketing File",
            page: "master/file"
          },
          {
            title: "Sliders",
            page: "master/slider"
          },
          {
            title: "News Feed",
            page: "master/news"
          },
          // {
          //   title: "Notification",
          //   page: "master/notification"
          // },
          {
            title: "Vehicle Level",
            page: "master/vehicleLevel"
          },
          {
            title: "Vehicle Brand",
            page: "master/vehicleMake"
          },
          {
            title: "Vehicle Model",
            page: "master/vehicleModel"
          },
          {
            title: "UserType",
            page: "master/userType"
          },
          {
            title: "Subscription",
            page: "master/subscription"
          },
          {
            title: "Settings",
            page: "master/admin/settings"
          },
        ]
      },
    ]
  }
};
