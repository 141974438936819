import { takeEvery, call, put } from 'redux-saga/effects';
import axios from 'axios';

function* postSpare(action) {
    try {
        
        const spareImgs = Object.keys(action.body.images);
        let formData = new FormData();
         formData.append('description', action.body.description);
         formData.append('sparesName', action.body.sparesName);
         formData.append('car', action.body.car);
         
         spareImgs.forEach(e => {
            formData.append('file', action.body.images[e]);
        });

        yield call(axios.post, `${ process.env.REACT_APP_API }/spares`, formData, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('access_token')}`,
            },
        });
        const { data } = yield call(axios.get, `${ process.env.REACT_APP_API }/spares`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('access_token')}`,
            },
        });
        yield put({ type: 'GET_ALL_SPARES', sliderTypes: data });
    } catch (e) {
        yield put({ type: 'ADD_SPARES', error: true });
    }
}

export default function* watchUPDATEMESSAGE() {
    yield takeEvery('ADD_SPARE', postSpare);
}
