const defaultState = {
    scopeList: [],
    scope: [],
    scopeResponse: {},
    updateResponse: {},
    error: false,
};

export default function scopeReducer(state = defaultState, action) {
    switch (action.type) {
        case 'GET_ALL_SCOPES': {
            return {
                ...state,
                error: action.error,
                scopeList: action.scopes,
                updateState: true,
            };
        }
        case 'GET_ALL_SCOPEPAGES': {
            return {
                ...state,
                error: action.error,
                scopeList: action.scopes,
                updateState: true,
            };
        }
        case 'GET_SCOPES': {
            return {
                ...state,
                error: action.error,
                scope: action.scope,
                updateGetState: true,
            };
        }
        case 'ADD_SCOPES': {
            return {
                ...state,
                error: action.error,
                scopeResponse: action.scopeResponse,
                addRoleState: true,
            };
        }
        case 'DELETE_SCOPES': {
            return {
                ...state,
                error: action.error,
                updateResponse: action.updateResponse,
                updateRoleState: true,
            };
        }
        case 'UPDATE_SCOPES': {
            return {
                ...state,
                error: action.error,
                updateResponse: action.updateResponse,
                updateRoleState: true,
            };
        }
        case 'UNSET_SCOPE_STATE': {
            return {
                ...state,
                updateState: false,
            };
        }
        case 'UNSET_GETSCOPE_STATE': {
            return {
                ...state,
                updateGetState: false,
            };
        }
        default: {
            return state;
        }
    }
}
