import { takeEvery, call, put } from 'redux-saga/effects';
import axios from 'axios';

function* updateUser(action) {
    try {
        
        let formData = new FormData();
        formData.append('firstName', action.body.firstName ? action.body.firstName : '');
        formData.append('role', action.body.role ? action.body.role : '');
        formData.append('lastName', action.body.lastName ? action.body.lastName : '');
        formData.append('contactNo', action.body.contactNo ? action.body.contactNo : '');
        formData.append('userId', action.body.userId ? action.body.userId : '');
        formData.append('email', action.body.email ? action.body.email : '');
        formData.append('designation', action.body.designation ? action.body.designation : '');
        formData.append('password', action.body.password ? action.body.password : '');
        formData.append('file', action.body.avatar ? action.body.avatar : '');

       yield call(axios.put, `${ process.env.REACT_APP_API }/user/${action.body.userId}`, formData, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('access_token')}`,
            },
        });
        
        const user = yield call(axios.get, `${ process.env.REACT_APP_API }/user/list/${action.body.userType}`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('access_token')}`,
            },
        });
        yield put({ type: 'GET_ALL_USERS', users: user.data });
    } catch (e) {
        
        yield put({ type: 'UPDATE_USERS', error: true });
    }
}

export default function* watchUPDATEMESSAGE() {
    yield takeEvery('UPDATE_USER', updateUser);
}
