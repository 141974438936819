import { combineReducers } from 'redux';
import country from './country';
import currency from './currency';
import language from './language';
import location from './location';
import me from './me';
import role from './role';
import scope from './scope';
import file from './file';
import service from './service';
import payments from './payments';
import user from './user';
import car from './car';
import vehicleLevel from './vehicleLevel';
import vehicleMake from './vehicleMake';
import vehicleModel from './vehicleModel';
import userType from './userType';
import adminJobCards from './adminJobCard';
import receptionJobCards from './receptionJobCard';
import workshopJobCards from './workshopJobCard';
import spares from './spare';
import settings from './setting';
import subService from './SubService';
import subscription from './subscription';
import feedback from './feedback';
import slider from './slider';
import news from './news';
import notification from './notification';




import * as auth from "../../store/ducks/auth.duck";
import { metronic } from "../../../_metronic";

export default combineReducers({
    car,
    subService,
    subscription,
    country,
    currency,
    language,
    location,
    me,
    role,
    scope,
    file,
    service,
    payments,
    user,
    auth: auth.reducer,
    i18n: metronic.i18n.reducer,
    builder: metronic.builder.reducer,
    vehicleLevel,
    vehicleMake,
    vehicleModel,
    userType,
    receptionJobCards,
    workshopJobCards,
    spares,
    settings,
    feedback,
    adminJobCards,
    slider,
    news,
    notification
});
