const defaultState = {
    serviceList: [],
    serviceResponse: {},
    updateResponse: {},
    errorResponse: {},
    error: false,
};

export default function serviceReducer(state = defaultState, action) {
    switch (action.type) {
        case 'GET_ALL_SERVICES': {
            return {
                ...state,
                error: action.error,
                serviceList: action.services,
                updateState: true,
            };
        }
        case 'GET_ALL_SERVICEPAGES': {
            return {
                ...state,
                error: action.error,
                serviceList: action.services,
                updateState: true,
            };
        }
        case 'GET_SERVICES': {
            return {
                ...state,
                error: action.error,
                service: action.service,
                updateGetState: true,
            };
        }
        case 'ADD_SERVICES': {
            return {
                ...state,
                error: action.error,
                serviceResponse: action.serviceResponse,
            };
        }
        case 'UPDATE_SERVICE_BANNERS': {
            return {
                ...state,
                error: action.error,
                updateRoleState: true,
            };
        }
        case 'UPDATE_SERVICES': {
            return {
                ...state,
                error: action.error,
                updateResponse: action.updateResponse,
                updateRoleState: true,
            };
        }
        case 'UNSET_SERVICE_STATE': {
            return {
                ...state,
                updateState: false,
            };
        }
        case 'UNSET_GETSERVICE_STATE': {
            return {
                ...state,
                updateGetState: false,
            };
        }
        case 'SERVICE_ERROR': {
            return {
                ...state,
                errorResponse: action.message,
            };
        }
        default: {
            return state;
        }
    }
}
