import { takeEvery, call, put } from 'redux-saga/effects';
import axios from 'axios';

function* deleteScope(actions) {
  try {
    yield call(axios.delete, `${process.env.REACT_APP_API}/scope/${actions.body.scopeId}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    });
    const { data } = yield call(axios.get, `${process.env.REACT_APP_API}/scope`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    });
    yield put({ type: 'GET_ALL_SCOPES', scopes: data });
  } catch (e) {
    yield put({ type: 'DELETE_SCOPES', error: true });
  }
}

export default function* watchUPDATEMESSAGE() {
  yield takeEvery('DELETE_SCOPE', deleteScope);
}
