const defaultState = {
  currencyList: [],
  currencyResponse: {},
  updateResponse: {},
  errorResponse: {},
  error: false,
};

export default function currencyReducer(state = defaultState, action) {
  switch (action.type) {
      case 'GET_ALL_CURRENCYS': {
          return {
              ...state,
              error: action.error,
              currencyList: action.currencys,
              updateState: true,
          };
      }
      case 'GET_CURRENCYS': {
          return {
              ...state,
              error: action.error,
              currency: action.currency,
              updateGetState: true,
          };
      }
      case 'ADD_CURRENCYS': {
          return {
              ...state,
              error: action.error,
              currencyResponse: action.currencyResponse,
          };
      }
      case 'UPDATE_CURRENCYS': {
          return {
              ...state,
              error: action.error,
              updateResponse: action.updateResponse,
              updateRoleState: true,
          };
      }
      case 'UNSET_CURRENCY_STATE': {
          return {
              ...state,
              updateState: false,
          };
      }
      case 'UNSET_GETCURRENCY_STATE': {
          return {
              ...state,
              updateGetState: false,
          };
      }
      case 'CURRENCY_ERROR': {
          return {
              ...state,
              errorResponse: action.message,
          };
      }
      default: {
          return state;
      }
  }
}
