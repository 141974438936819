const defaultState = {
    carList: [],
    carResponse: {},
    updateResponse: {},
    errorResponse: {},
    error: false,
};

export default function carReducer(state = defaultState, action) {
    switch (action.type) {
        case 'GET_ALL_CARS': {
            return {
                ...state,
                error: action.error,
                carList: action.cars,
                updateState: true,
            };
        }
        case 'GET_CARS': {
            return {
                ...state,
                error: action.error,
                carResponse: action.car,
                updateGetState: true,
            };
        }
        case 'ADD_CARS': {
            return {
                ...state,
                error: action.error,
                carResponse: action.carResponse,
            };
        }
        case 'UPDATE_CARS': {
            return {
                ...state,
                error: action.error,
                updateResponse: action.updateResponse,
                updateRoleState: true,
            };
        }
        case 'APPROVE_CARS': {
            return {
                ...state,
                error: action.error,
                updateResponse: action.updateResponse,
            };
        }
        case 'DECLINE_CARS': {
            return {
                ...state,
                error: action.error,
                updateResponse: action.updateResponse,
            };
        }
        case 'DELETE_CARS': {
            return {
                ...state,
                error: action.error,
                deleteResponse: action.deleteResponse,
                deleteState: true,
            };
        }
        case 'UNSET_CAR_STATE': {
            return {
                ...state,
                updateState: false,
            };
        }
        case 'UNSET_GETCAR_STATE': {
            return {
                ...state,
                updateGetState: false,
            };
        }
        case 'CAR_ERROR': {
            return {
                ...state,
                errorResponse: action.message,
            };
        }
        default: {
            return state;
        }
    }
}
