import React, { Component } from "react";
import { Link } from "react-router-dom";
import classNames from 'classnames';
import { Alert, Spinner } from 'react-bootstrap'
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { FormattedMessage } from "react-intl";
import axios from 'axios'
import * as MeActions from "../../ReduxStore/Actions/me";

import './Login.scss'

class Login extends Component {

  constructor(props) {
    super(props);
    this.state = {
      username: '',
      password: '',
      browserToken: '',
      error: false,
      errorMessage: '',
      loading: false,
      navigate: true,
      showPass: false,
      passwordType: 'password'
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.me.updateState) {
      nextProps.MeActions.UpdateGetMeState();
      nextProps.history.push('/dashboard');
      return {
        loading: false,
        ...prevState
      }
    }
    return {
      ...prevState,
    }
  };

  componentDidMount() {
    const token = localStorage.getItem('notificationID');
    if (token) {
      this.setState({
        browserToken: localStorage.getItem('notificationID')
      })
    }
  }

  handleChange(data, fieldName) {
    this.setState(prevState => ({
      ...prevState,
      [fieldName]: data,
    }));
  }

  handleShowPassword() {
    const { showPass } = this.state;
    this.setState({ showPass: !this.state.showPass })
    if (showPass === true) {
      this.setState({ passwordType: 'Text' })
    }
    else {
      this.setState({ passwordType: 'password' })
    }
  }

  async handleSubmit() {
    const { MeActions } = this.props;
    const { username, password, browserToken } = this.state;
    try {
      if (username && password) {
        this.setState({
          loading: true
        });
        const { data } = await axios.post(`${process.env.REACT_APP_API}/auth/adminLogin`, { username, password, browserToken });
        if (data) {
          localStorage.setItem('access_token', data.token);
          MeActions.getMe();
        }
      } else {
        this.setState({
          loading: false,
          error: true,
          errorMessage: 'Username and password is required!'
        });
      }
    } catch (e) {
      this.setState({
        loading: false,
        error: true,
        errorMessage: e.response.data.message
      });
    }
  }

  render() {
    const { error, errorMessage, username, password, loading, showPass, passwordType } = this.state;
    return (
      <>
        <div className="login__container">
          <div className="container__poster">
            <figure>
              <img src="https://storage.googleapis.com/exitone/97149eee_ExitOnelogincar-dribble.gif" alt="" />
            </figure>
          </div>
          <div className="container__form">
            <div className="inner-container">
              <div className="exit-login__logo">
                <Link to="/">
                  <img src="https://storage.googleapis.com/exitone/41d90acf_logo_(1).png" alt="" />
                </Link>
              </div>
              <div className="kt-login__signin__box">
                <div className="exit-login__head">
                  <h3 className="kt-login__title">
                    <FormattedMessage id="AUTH.LOGIN.TITLE" />
                  </h3>
                </div>
                {
                  error ? (
                    <Alert key='1' variant='warning'>
                      {errorMessage}
                    </Alert>
                  ) : ''
                }
                <div className="form__box">
                  <div className="input-group">
                    <input className="form-control" type="text" placeholder="Email" name="email" autoComplete="off" value={username} onChange={(e) => this.handleChange(e.target.value, 'username')} />
                  </div>
                  <div className="input-group">
                    <input className="form-control" type={passwordType} placeholder="Password" name="password" autoComplete="off" value={password} onChange={(e) => this.handleChange(e.target.value, 'password')} />
                    <div className="icon__holder" onClick={() => { this.handleShowPassword() }}>
                      <i className={classNames('fa', { 'fa-eye': showPass === true }, { 'fa-eye-slash': showPass === false })} aria-hidden="true"></i>
                    </div>
                  </div>

                  <div className="btn-tray">
                    {
                      loading ? <button id="kt_login_signin_submit" className="btn btn-brand btn-elevate kt-login__btn-primary" disabled>Sign In <Spinner animation="border" style={{ width: '2rem', height: '2rem', color: 'white !important' }} /></button>
                        : <button id="kt_login_signin_submit" className="btn btn-brand btn-elevate kt-login__btn-primary" onClick={() => this.handleSubmit()}>Sign In</button>
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </>
    );
  }
}

const mapStateToProps = ({ me }) => ({
  me
});

function mapDispatchToProps(dispatch) {
  return {
    MeActions: bindActionCreators(MeActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);

