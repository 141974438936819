import { takeEvery, call, put } from 'redux-saga/effects';
import axios from 'axios';

function* fetchService(actions) {
    try {
        const { data } = yield call(axios.get, `${ process.env.REACT_APP_API }/services/${ actions.body.servicesId }`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('access_token')}`,
            },
        });
        yield put({ type: 'GET_SERVICES', service: data });
    } catch (e) {
        yield put({ type: 'SERVICE_ERROR', message: e, error: true });
    }
}

export default function* watchUPDATEMESSAGE() {
    yield takeEvery('GET_SERVICE', fetchService);
}
