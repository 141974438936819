import { takeEvery, call, put } from 'redux-saga/effects';
import axios from 'axios';

function* fetchAllPayment(action) {
    
    try {
        const { data } = yield call(axios.post, `${ process.env.REACT_APP_API }/payments`, {}, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('access_token')}`,
            },
        });
        yield put({ type: 'GET_ALL_PAYMENTS', payments: data });
    } catch (e) {
        yield put({ type: 'PAYMENT_ERROR', message: e, error: true });
    }
}

export default function* watchUPDATEMESSAGE() {
    yield takeEvery('GET_ALL_PAYMENT', fetchAllPayment);
}
