import { takeEvery, call, put } from 'redux-saga/effects';
import axios from 'axios';

function* acceptCar(action) {
    try {
        yield call(axios.put, `${process.env.REACT_APP_API}/cars/declineCar/${action.body.carsId}`, { ...action.body }, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('access_token')}`,
            },
        });
        const { data } = yield call(axios.post, `${process.env.REACT_APP_API}/cars/1/10`, {...action.body}, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('access_token')}`,
            },
        });
        yield put({ type: 'GET_ALL_CARS', cars: data });
    } catch (e) {
        yield put({ type: 'DECLINE_CARS', error: true });
    }
}

export default function* watchUPDATEMESSAGE() {
    yield takeEvery('DECLINE_CAR', acceptCar);
}
