import { takeEvery, call, put } from 'redux-saga/effects';
import axios from 'axios';

function* cancelBooking(action) {
    try {

        yield call(axios.put, `${process.env.REACT_APP_API}/transactionHeader/cancel/${action.body.bookingId}`, { ...action.body }, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('access_token')}`,
            },
        });
        const { data } = yield call(axios.post, `${process.env.REACT_APP_API}/transactionHeader/getReceptionJobCard/1/10`, { ...action.body }, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('access_token')}`,
            },
        });
        yield put({ type: 'GET_ALL_RECEPTION_JOB_CARDS', jobcards: data });

    } catch (e) {
        yield put({ type: 'RECEPTION_JOB_CARD_ERROR', error: true });
    }
}

export default function* watchUPDATEMESSAGE() {
    yield takeEvery('CANCEL_JOB_CARD_BOOKING', cancelBooking);
}