import { takeEvery, call, put } from 'redux-saga/effects';
import axios from 'axios';

function* fetchAllRoles()
{
    try
    {
        const { data } = yield call(axios.get, `${ process.env.REACT_APP_API }/role`, {
            headers: {
                Authorization: `Bearer ${ localStorage.getItem('access_token') }`,
            },
        });
        yield put({ type: 'GET_ALL_ROLES', roles: data });
    } catch (e)
    {
        yield put({ type: 'GET_ALL_ROLES', error: true });
    }
}

export default function* watchUPDATEMESSAGE()
{
    yield takeEvery('GET_ALL_ROLE', fetchAllRoles);
}
