import { takeEvery, call, put } from 'redux-saga/effects';
import axios from 'axios';

function* postUserType(action) {
    try {

        yield call(axios.post, `${process.env.REACT_APP_API}/userType`, { ...action.body }, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('access_token')}`,
            },
        });
        const { data } = yield call(axios.get, `${process.env.REACT_APP_API}/userType`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('access_token')}`,
            },
        });
        yield put({ type: 'GET_ALL_USERTYPES', userTypes: data });
    } catch (e) {
        yield put({ type: 'ADD_USERTYPES', error: true });
    }
}

export default function* watchUPDATEMESSAGE() {
    yield takeEvery('ADD_USERTYPE', postUserType);
}
