import { takeEvery, call, put } from 'redux-saga/effects';
import axios from 'axios';

function* postNews(action) {
    try {
        let formData = new FormData();
        formData.append('file', action.body.thumbnail);
        formData.append('description', action.body.description);
        formData.append('descriptionArabic', action.body.descriptionArabic);
        formData.append('title', action.body.title);
        formData.append('titleArabic', action.body.titleArabic);
        formData.append('url', action.body.url);


        yield call(axios.post, `${process.env.REACT_APP_API}/news`, formData, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('access_token')}`,
            },
        });
        const news = yield call(axios.post, `${process.env.REACT_APP_API}/news/1/10`, { ...action.body }, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('access_token')}`,
            },
        });
        yield put({ type: 'GET_ALL_NEWSS', newss: news.data });
    } catch (e) {
        yield put({ type: 'ADD_NEWSS', error: true });
    }
}

export default function* watchUPDATEMESSAGE() {
    yield takeEvery('ADD_NEWS', postNews);
}
