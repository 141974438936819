import { all, fork } from 'redux-saga/effects';

import country from './country';
import currency from './currency';
import language from './language';
import location from './location';
import me from './me';
import file from './file';
import role from './role';
import scope from './scope';
import service from './service';
import Payments from './payments';
import user from './user';
import car from './car';
import vehicleLevel from './vehicleLevel';
import vehicleMake from './vehicleMake';
import vehicleModel from './vehicleModel';
import userType from './userType';
import adminJobCard from './AdminJobCard';
import receptionJobCard from './ReceptionJobCard';
import workshopJobCard from './WorkshopJobCard';
import spare from './spare';
import setting from './setting';
import subService from './SubService';
import subscription from './subscription';
import feedback from './feedback';
import slider from './slider';
import news from './news';
import notification from './notification';



import * as auth from "../../store/ducks/auth.duck";

const sagas = [
    ...country,
    ...currency,
    ...language,
    ...location,
    ...subService,
    ...subscription,
    ...me,
    ...file,
    ...role,
    ...scope,
    ...service,
    ...user,
    ...Payments,
    ...vehicleLevel,
    ...userType,
    ...vehicleMake,
    ...vehicleModel,
    ...car,
    ...receptionJobCard,
    ...workshopJobCard,
    ...spare,
    ...setting,
    ...feedback,
    ...adminJobCard,
    ...slider,
    ...news,
    ...notification
];

/**
 * @function
 * @generator
 * @name RootSaga
 * @returns {Function} Yields redux-sage/effects/all using sagas.
 */
export default function* rootSaga() {
    yield all(sagas.map(saga => fork(saga)));
    yield all([auth.saga()]);
}
