import { takeEvery, call, put } from 'redux-saga/effects';
import axios from 'axios';

function* postSubService(action) {
    try {
        yield call(axios.post, `${process.env.REACT_APP_API}/subService`, { ...action.body }, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('access_token')}`,
            },
        });
        const { data } = yield call(axios.post, `${process.env.REACT_APP_API}/subService/1/10`, { ...action.body }, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('access_token')}`,
            },
        });
        yield put({ type: 'GET_ALL_SUB_SERVICEPAGES', subServices: data });
    } catch (e) {
        yield put({ type: 'ADD_SUB_SERVICES', error: true });
    }
}

export default function* watchUPDATEMESSAGE() {
    yield takeEvery('ADD_SUB_SERVICE', postSubService);
}
