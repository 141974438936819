const defaultState = {
  languageList: [],
  languageResponse: {},
  updateResponse: {},
  errorResponse: {},
  error: false,
};

export default function languageReducer(state = defaultState, action) {
  switch (action.type) {
      case 'GET_ALL_LANGUAGES': {
          return {
              ...state,
              error: action.error,
              languageList: action.languages,
              updateState: true,
          };
      }
      case 'GET_LANGUAGES': {
          return {
              ...state,
              error: action.error,
              language: action.language,
              updateGetState: true,
          };
      }
      case 'ADD_LANGUAGES': {
          return {
              ...state,
              error: action.error,
              languageResponse: action.languageResponse,
          };
      }
      case 'UPDATE_LANGUAGES': {
          return {
              ...state,
              error: action.error,
              updateResponse: action.updateResponse,
              updateRoleState: true,
          };
      }
      case 'UNSET_LANGUAGE_STATE': {
          return {
              ...state,
              updateState: false,
          };
      }
      case 'UNSET_GETLANGUAGE_STATE': {
          return {
              ...state,
              updateGetState: false,
          };
      }
      case 'LANGUAGE_ERROR': {
          return {
              ...state,
              errorResponse: action.message,
          };
      }
      default: {
          return state;
      }
  }
}
