import GetAllJobs from './GetAllJobs';
import GetAllCancelledJobs from './GetAllCancelledJobs';
import CancelBooking from './CancelBooking';
import UpdateReceptionImage from './UpdateReceptionImage';
import UpdateReceptionSpare from './UpdateReceptionSpare';
import GetAllJobCard from './GetAllJobCard';
import GetAllAppoinmentJobCards from './GetAllAppoinmentJobs';



export default [GetAllJobs, GetAllCancelledJobs, GetAllJobCard, GetAllAppoinmentJobCards, CancelBooking, UpdateReceptionImage, UpdateReceptionSpare];
