import { takeEvery, call, put } from 'redux-saga/effects';
import axios from 'axios';

function* fetchCountry(actions) {
    try {
        const { data } = yield call(axios.get, `${ process.env.REACT_APP_API }/country/${ actions.body.countryId }`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('access_token')}`,
            },
        });
        yield put({ type: 'GET_COUNTRYS', country: data });
    } catch (e) {
        yield put({ type: 'USER_ERROR', message: e, error: true });
    }
}

export default function* watchUPDATEMESSAGE() {
    yield takeEvery('GET_COUNTRY', fetchCountry);
}
