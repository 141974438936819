import { takeEvery, call, put } from 'redux-saga/effects';
import axios from 'axios';

function* updateSlider(action) {
    try {
        let formData = new FormData();
        formData.append('file', action.body.avatar);
        formData.append('description', action.body.description);
        formData.append('type', action.body.type);
        formData.append('url', action.body.url);
        formData.append('videoId', action.body.videoId);
        formData.append('sliderId', action.body.sliderId);
        formData.append('mediatype', action.body.mediatype);

        yield call(axios.put, `${process.env.REACT_APP_API}/slider/${action.body.sliderId}`, formData, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('access_token')}`,
            },
        });
        const slider = yield call(axios.post, `${process.env.REACT_APP_API}/slider/1/10`, { ...action.body }, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('access_token')}`,
            },
        });
        yield put({ type: 'GET_ALL_SLIDERS', sliders: slider.data });
    } catch (e) {
        yield put({ type: 'UPDATE_SLIDERS', error: true });
    }
}

export default function* watchUPDATEMESSAGE() {
    yield takeEvery('UPDATE_SLIDER', updateSlider);
}
