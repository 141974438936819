import { takeEvery, call, put } from 'redux-saga/effects';
import axios from 'axios';

function* fetchAllServices() {
    try {
        const { data } = yield call(axios.get, `${ process.env.REACT_APP_API }/services`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('access_token')}`,
            },
        });
        yield put({ type: 'GET_ALL_SERVICES', services: data });
    } catch (e) {
        yield put({ type: 'SERVICE_ERROR', message: e, error: true });
    }
}

export default function* watchUPDATEMESSAGE() {
    yield takeEvery('GET_ALL_SERVICE', fetchAllServices);
}
