import { takeEvery, call, put } from 'redux-saga/effects';
import axios from 'axios';

function* fetchAllUsers({body}) {
    try {
        const { data } = yield call(axios.get, `${ process.env.REACT_APP_API }/user/list/${body.userType}/${body.page}/${body.limit}`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('access_token')}`,
            },
        });
        yield put({ type: 'GET_ALL_USERS', users: data });
    } catch (e) {
        yield put({ type: 'USER_ERROR', message: e, error: true });
    }
}

export default function* watchUPDATEMESSAGE() {
    yield takeEvery('GET_ALL_USER', fetchAllUsers);
}
