import { call, put, takeLatest } from 'redux-saga/effects';
import axios from 'axios';

function* getMe(action) {
    try {
        const { data: { user } } = yield call(axios.get, `${ process.env.REACT_APP_API }/auth/adminMe`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
          }
        });

        yield put({ type: 'GET_MES', user });
      } catch (e) {
        yield put({ type: 'SET_ERROR', error: true });
    }
}

export default function* watchFetchResults() {
    yield takeLatest('GET_ME', getMe);
}
