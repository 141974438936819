import { takeEvery, call, put } from 'redux-saga/effects';
import axios from 'axios';

function* updateFile(action) {
    try {
        let formData = new FormData();
        formData.append('file', action.body.avatar);
        formData.append('description', action.body.description);
        formData.append('filesId', action.body.filesId);

        yield call(axios.put, `${process.env.REACT_APP_API}/files/${action.body.filesId}`, formData, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('access_token')}`,
            },
        });
        const file = yield call(axios.post, `${process.env.REACT_APP_API}/files/1/10`, { ...action.body }, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('access_token')}`,
            },
        });
        yield put({ type: 'GET_FILES', file: file.data });
    } catch (e) {
        yield put({ type: 'UPDATE_FILES', error: true });
    }
}

export default function* watchUPDATEMESSAGE() {
    yield takeEvery('UPDATE_FILE', updateFile);
}
