const defaultState = {
    subServiceList: [],
    subServiceResponse: {},
    updateResponse: {},
    errorResponse: {},
    error: false,

};

export default function SubServiceReducer(state = defaultState, action)
{
    switch (action.type)
    {
        case 'GET_ALL_SUB_SERVICES': {
            return {
                ...state,
                error: action.error,
                subServiceList: action.subServices,
                updateState: true,
            };
        }
        case 'GET_ALL_SUB_SERVICEPAGES': {
            return {
                ...state,
                error: action.error,
                subServiceList: action.subServices,
                updateState: true,
            };
        }
        case 'GET_SUB_SERVICES': {
            return {
                ...state,
                error: action.error,
                subServiceResponse: action.subService,
                updateGetState: true,
            };
        }
        case 'ADD_SUB_SERVICES': {
            return {
                ...state,
                error: action.error,
                subServiceResponse: action.subServiceResponse,
            };
        }
        case 'UPDATE_SUB_SERVICES': {
            return {
                ...state,
                error: action.error,
                updateResponse: action.updateResponse,
                updateRoleState: true,
            };
        }
        case 'UNSET_SUB_SERVICE_STATE': {
            return {
                ...state,
                updateState: false,
            };
        }
        case 'UNSET_GETSUB_SERVICE_STATE': {
            return {
                ...state,
                updateGetState: false,
            };
        }
        case 'SUB_SERVICE_ERROR': {
            return {
                ...state,
                errorResponse: action.message,
            };
        }
        default: {
            return state;
        }
    }
}
