const defaultState = {
    jobCardList: [],
    errorResponse: {},
    updateResponse: {},
    error: false,
};

export default function ratingReducer(state = defaultState, action)
{
    switch (action.type)
    {
        case 'GET_ALL_WORKSHOP_JOB_CARDS': {
            return {
                ...state,
                error: action.error,
                jobCardList: action.jobcards,
                updateState: true,
            };
        }
        case 'GET_WORKSHOP_JOB_CARDS': {
            return {
                ...state,
                error: action.error,
                jobCardList: action.jobcards,
                updateState: true,
            };
        }
        case 'GET_ALL_CANCELLED_WORKSHOP_JOB_CARDS': {
            return {
                ...state,
                error: action.error,
                jobCardList: action.jobcards,
                updateState: true,
            };
        }
        case 'UNSET_GET_ALL_WORKSHOP_JOB_CARD_STATE': {
            return {
                ...state,
                updateState: false,
            };
        }
        case 'WORKSHOP_JOB_CARD_ERROR': {
            return {
                ...state,
                errorResponse: action.message,
            };
        }
        case 'UPDATE_WORKSHOP_IMAGES': {
            return {
                ...state,
                error: action.error,
            };
        }
        case 'UPDATE_SPARE_PARTS': {
            return {
                ...state,
                error: action.error,
            };
        }
        case 'UPDATE_STATUS': {
            return {
                ...state,
                error: action.error,
            };
        }
        case 'EXTEND_WORK_TIMES': {
            return {
                ...state,
                error: action.error,
            };
        }
        default: {
            return state;
        }
    }
}