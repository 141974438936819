import { takeEvery, call, put } from 'redux-saga/effects';
import axios from 'axios';

function* updateVehicleLevel(action) {
    try {
        yield call(axios.put, `${process.env.REACT_APP_API}/vehicleLevels/${action.body.vehicleLevelsId}`, { ...action.body }, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('access_token')}`,
            },
        });
        const { data } = yield call(axios.get, `${process.env.REACT_APP_API}/vehicleLevels`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('access_token')}`,
            },
        });
        yield put({ type: 'GET_ALL_VEHICLELEVELS', vehicleLevels: data });
    } catch (e) {
        yield put({ type: 'UPDATE_VEHICLELEVELS', error: true });
    }
}

export default function* watchUPDATEMESSAGE() {
    yield takeEvery('UPDATE_VEHICLELEVEL', updateVehicleLevel);
}
