import { takeEvery, call, put } from 'redux-saga/effects';
import axios from 'axios';

function* fetchAllCurrency() {
    try {
        const { data } = yield call(axios.get, `${ process.env.REACT_APP_API }/currency`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('access_token')}`,
            },
        });
        yield put({ type: 'GET_ALL_CURRENCYS', currencys: data });
    } catch (e) {
        yield put({ type: 'CURRENCY_ERROR', message: e, error: true });
    }
}

export default function* watchUPDATEMESSAGE() {
    yield takeEvery('GET_ALL_CURRENCY', fetchAllCurrency);
}
