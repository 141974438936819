import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom"
import axios from 'axios';
import { bindActionCreators } from "redux";
import { Line, Pie } from 'react-chartjs-2';

// Actions 
import * as UserActions from '../../ReduxStore/Actions/user';

//UI
import './Dashboard.scss';


const CounterCard = ({ order, iconName, value, title }) => {
    return (
        <div className="counter-card">
            <div className={ `card-icon card-${order}` }>
                <i className={ iconName }></i>
            </div>
            <div className="counter-container">
                <div className="card-value">{ value }</div>
                <div className="card-title">{ title }</div>
            </div>
        </div>
    )
}

class Dashboard extends Component {

    constructor(props) {
        super(props);
        this.state = {
            count: '',
            chartData: '',
            loading: false
        }
    }

    componentDidMount() {
        this.adminDashboard();
    }

    async adminDashboard() {
        const { data } = await axios.get(`${process.env.REACT_APP_API}/user/adminDashboard`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('access_token')}`,
            },
        });
        this.setState({
            count: data,
            chartData: data,
        })
    }

    renderMonths(value) {
        switch (value) {
            case 0: {
                return 'January'
            }
            case 1: {
                return 'February'
            }
            case 2: {
                return 'March'
            }
            case 3: {
                return 'April'
            }
            case 4: {
                return 'May'
            }
            case 5: {
                return 'June'
            }
            case 6: {
                return 'July'
            }
            case 7: {
                return 'August'
            }
            case 8: {
                return 'September'
            }
            case 9: {
                return 'October'
            }
            case 10: {
                return 'November'
            }
            case 11: {
                return 'December'
            }
            default:
                break;
        }
    }

    renderbookingsByStatusLineChart() {
        const { chartData: { bookingsByStatus } } = this.state;

        const Labels = bookingsByStatus && bookingsByStatus.map(data => data.label);
        const Values = bookingsByStatus && bookingsByStatus.map(data => data.value);

        return {
            labels: Labels,
            datasets: [
                {
                    label: Labels,
                    data: Values,
                    backgroundColor: [
                        '#FFA800', //received
                        '#5FC04E', //Confirmed
                        '#1BC5BD', //Delivered
                        '#F64E60', //Cancelled
                    ],
                    borderColor: [
                        '#FFA800', //received
                        '#5FC04E', //Confirmed
                        '#1BC5BD', //Delivered
                        '#F64E60', //Cancelled
                    ],
                    borderWidth: 1,
                },
            ],
        }
    }

    renderUserCharts() {
        const { chartData: { userChart } } = this.state;

        const Labels = userChart && userChart.map(data => data.label);
        const Values = userChart && userChart.map(data => data.value);

        return {
            labels: Labels,
            datasets: [
                {
                    label: Labels,
                    data: Values,
                    backgroundColor: [
                        '#FFA800', //received
                        '#5FC04E', //Confirmed
                    ],
                    borderColor: [
                        '#FFA800', //received
                        '#5FC04E', //Confirmed
                    ],
                    borderWidth: 1,
                },
            ],
        }
    }


    handleMonthlySale() {
        const { chartData: { monthSales } } = this.state;
        const month = monthSales && monthSales.map(data => data.x.month);
        return month && month.map(o => this.renderMonths(o))
    }

    renderMonthlySaleLineChart() {
        const { chartData: { monthSales } } = this.state;
        return {
            labels: this.handleMonthlySale(),
            datasets: [
                {
                    label: 'Total Sales',
                    fill: false,
                    lineTension: 0.1,
                    backgroundColor: 'rgba(75,192,192,0.4)',
                    borderColor: 'rgba(75,192,192,1)',
                    borderCapStyle: 'butt',
                    borderDash: [],
                    borderDashOffset: 0.0,
                    borderJoinStyle: 'miter',
                    pointBorderColor: 'rgba(75,192,192,1)',
                    pointBackgroundColor: '#fff',
                    pointBorderWidth: 1,
                    pointHoverRadius: 10,
                    pointHoverBackgroundColor: 'rgba(75,192,192,1)',
                    pointHoverBorderColor: 'rgba(220,220,220,1)',
                    pointHoverBorderWidth: 2,
                    pointRadius: 3,
                    pointHitRadius: 10,
                    data: monthSales ? monthSales : []
                }
            ]
        }
    }

    render() {

        const { count } = this.state;
        const { me: { user } } = this.props;

        var formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'SAR',
        });

        const totalEarning = count ? formatter.format(count.totalEarnings[0].value) : "";

        return (
            <Fragment>
                {
                    user && user.role.name === "admin" && (
                        <Fragment>
                            <div className="dashboard-view">
                                <div className="db-row counter--cards">
                                    <CounterCard
                                        order="6"
                                        iconName="fa fa-user-circle"
                                        value={ count.totalUsers }
                                        title="Customers"
                                    />
                                    <CounterCard
                                        order="1"
                                        iconName="fa fa-car"
                                        value={ count.totalCars }
                                        title="Cars"
                                    />
                                    <CounterCard
                                        order="6"
                                        iconName="fa fa-address-book"
                                        value={ count.totalBookings }
                                        title="Bookings"
                                    />
                                    <CounterCard
                                        order="6"
                                        iconName="fa fa-money"
                                        value={ totalEarning }
                                        title="Earning"
                                    />
                                    <CounterCard
                                        order="6"
                                        iconName="fa fa-users"
                                        value={ count.companyUsers }
                                        title="Company Users"
                                    />
                                </div>
                                <div className="db-row charts">
                                    <div className="chart">
                                        <Pie data={ this.renderbookingsByStatusLineChart() } />
                                        <div className="chart-title">Bookings</div>
                                    </div>
                                    <div className="chart">
                                        <Pie data={ this.renderUserCharts() } />
                                        <div className="chart-title">Users</div>
                                    </div>
                                    <div className="chart">
                                        <Line data={ this.renderMonthlySaleLineChart() } />
                                    </div>
                                </div>
                            </div>
                        </Fragment>
                    )
                }

                {
                    user && user.role.name === "Workshop" && (
                        <Redirect to="master/workshop/jobcard" />
                    )
                }
                {
                    user && user.role.name === "Receptionist" && (
                        <Redirect to="master/reception/jobcard" />
                    )
                }
            </Fragment>
        )
    }
}

const mapStateToProps = ({ me, user }) => ({
    user, me
});

function mapDispatchToProps(dispatch) {
    return {
        UserActions: bindActionCreators(UserActions, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);