import { takeEvery, call, put } from 'redux-saga/effects';
import axios from 'axios';

function* fetchScope(actions)
{
    try
    {
        const { data } = yield call(axios.get, `${ process.env.REACT_APP_API }/scope/${ actions.body.scopeId }`, {
            headers: {
                Authorization: `Bearer ${ localStorage.getItem('access_token') }`,
            },
        });
        yield put({ type: 'GET_SCOPES', scope: data });
    } catch (e)
    {
        yield put({ type: 'GET_SCOPES', error: true });
    }
}

export default function* watchUPDATEMESSAGE()
{
    yield takeEvery('GET_SCOPE', fetchScope);
}
