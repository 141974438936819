const defaultState = {
    subscriptionList: [],
    subscriptionResponse: {},
    updateResponse: {},
    errorResponse: {},
    error: false,
};

export default function subscriptionReducer(state = defaultState, action) {
    switch (action.type) {
        case 'GET_ALL_SUBSCRIPTIONS': {
            return {
                ...state,
                error: action.error,
                subscriptionList: action.subscriptions,
                updateState: true,
            };
        }
        case 'GET_SUBSCRIPTIONS': {
            return {
                ...state,
                error: action.error,
                subscriptionResponse: action.subscription,
                updateGetState: true,
            };
        }
        case 'ADD_SUBSCRIPTIONS': {
            return {
                ...state,
                error: action.error,
                subscriptionResponse: action.subscriptionResponse,
            };
        }
        case 'UPDATE_SUBSCRIPTIONS': {
            return {
                ...state,
                error: action.error,
                updateResponse: action.updateResponse,
                updateRoleState: true,
            };
        }
        case 'DELETE_SUBSCRIPTIONS': {
            return {
                ...state,
                error: action.error,
                deleteResponse: action.deleteResponse,
                deleteState: true,
            };
        }
        case 'UNSET_SUBSCRIPTION_STATE': {
            return {
                ...state,
                updateState: false,
            };
        }
        case 'UNSET_GETSUBSCRIPTION_STATE': {
            return {
                ...state,
                updateGetState: false,
            };
        }
        case 'SUBSCRIPTION_ERROR': {
            return {
                ...state,
                errorResponse: action.message,
            };
        }
        default: {
            return state;
        }
    }
}
