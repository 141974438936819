const defaultState = {
    settingsList: [],
    settingResponse: {},
    updateResponse: {},
    errorResponse: {},
    error: false,
};

export default function spareReducer(state = defaultState, action) {
    switch (action.type) {
        case 'GET_ALL_SETTINGS': {
            return {
                ...state,
                error: action.error,
                settingsList: action.settings,
                updateState: true,
            };
        }
        case 'UPDATE_SETTINGS': {
            return {
                ...state,
                error: action.error,
                updateResponse: action.updateResponse,
                updateState: true,
            };
        }
        case 'UNSET_SETTING_STATE': {
            return {
                ...state,
                updateState: false,
            };
        }
        case 'UNSET_GETSETTING_STATE': {
            return {
                ...state,
                updateGetState: false,
            };
        }
        case 'SETTING_ERROR': {
            return {
                ...state,
                errorResponse: action.message,
            };
        }
        default: {
            return state;
        }
    }
}
