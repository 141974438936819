import { takeEvery, call, put } from 'redux-saga/effects';
import axios from 'axios';

function* postUser(action)
{
    try
    {
        let formData = new FormData();
        if (action.body.firstName) formData.append('firstName', action.body.firstName);
        if (action.body.lastName) formData.append('lastName', action.body.lastName);
        if (action.body.email) formData.append('email', action.body.email);
        if (action.body.isActive) formData.append('isActive', action.body.isActive);
        if (action.body.contactNo) formData.append('contactNo', action.body.contactNo);
        if (action.body.designation) formData.append('designation', action.body.designation);
        if (action.body.userType) formData.append('userType', action.body.userType);
        if (action.body.role) formData.append('role', action.body.role);
        if (action.body.password) formData.append('password', action.body.password);
        if (action.body.city) formData.append('city', action.body.city);
        if (action.body.state) formData.append('state', action.body.state);
        if (action.body.country) formData.append('country', action.body.country);
        if (action.body.vehicleType) formData.append('vehicleType', action.body.vehicleType);
        if (action.body.vehicleName) formData.append('vehicleName', action.body.vehicleName);
        if (action.body.vehicleNo) formData.append('vehicleNo', action.body.vehicleNo);
        if (action.body.chasisNo) formData.append('chasisNo', action.body.chasisNo);
        if (action.body.nationalId) formData.append('nationalId', action.body.nationalId);
        if (action.body.licenseNo) formData.append('licenseNo', action.body.licenseNo);
        if (action.body.vehicleInsuranceNo) formData.append('vehicleInsuranceNo', action.body.vehicleInsuranceNo);
        if (action.body.vehicleRegistrationNo) formData.append('vehicleRegistrationNo', action.body.vehicleRegistrationNo);
        if (action.body.vehicleInsuranceImage) formData.append('file', action.body.vehicleInsuranceImage);
        if (action.body.vehicleRegistrationImage) formData.append('file', action.body.vehicleRegistrationImage);


        yield call(axios.post, `${ process.env.REACT_APP_API }/user`, formData, {
            headers: {
                Authorization: `Bearer ${ localStorage.getItem('access_token') }`,
            },
        });
        const user = yield call(axios.get, `${ process.env.REACT_APP_API }/user/list/${ action.body.userType }`, {
            headers: {
                Authorization: `Bearer ${ localStorage.getItem('access_token') }`,
            },
        });
        yield put({ type: 'GET_ALL_USERS', users: user.data });
    } catch (e)
    {
        yield put({ type: 'ADD_USERS', error: true });
    }
}

export default function* watchUPDATEMESSAGE()
{
    yield takeEvery('ADD_USER', postUser);
}
