const defaultState = {
    userList: [],
    customerCount: {},
    userResponse: {},
    updateResponse: {},
    errorResponse: {},
    passwordResponse: '',
    error: false,
};

export default function userReducer(state = defaultState, action) {
    switch (action.type) {
        case 'GET_ALL_CUSTOMER_COUNTS': {
            return {
                ...state,
                error: action.error,
                customerCount: action.customerCount,
                updateState: true,
            };
        }
        case 'CUSTOM_SEARCH_USERS':
        case 'CUSTOM_FILTER_USERS':
        case 'GET_ALL_USERS': {
            return {
                ...state,
                error: action.error,
                userList: action.users,
                updateState: true,
            };
        }
        case 'GET_USERS': {
            return {
                ...state,
                error: action.error,
                user: action.user,
                updateGetState: true,
            };
        }
        case 'DELETE_CUSTOMERS': {
            return {
                ...state,
                error: action.error,
                deleteState: true,
            };
        }
        case 'ADD_USERS': {
            return {
                ...state,
                error: action.error,
                userResponse: action.userResponse,
            };
        }
        case 'UPDATE_USERS': {
            return {
                ...state,
                error: action.error,
                updateResponse: action.updateResponse,
                updateRoleState: true,
            };
        }
        case 'CHANGE_USER_PASSWORDS': {
            return {
                ...state,
                error: action.error,
                passwordResponse: action.passwordResponse,
                updateState: true,
            };
        }
        case 'UNSET_USER_STATE': {
            return {
                ...state,
                updateState: false,
            };
        }
        case 'UNSET_GETUSER_STATE': {
            return {
                ...state,
                updateGetState: false,
            };
        }
        case 'USER_ERROR': {
            return {
                ...state,
                errorResponse: action.message,
            };
        }
        default: {
            return state;
        }
    }
}
