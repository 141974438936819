export const GetCustomerCount = body => async (dispatch) => {
    try {
        dispatch({
            body,
            type: 'GET_ALL_CUSTOMER_COUNT',
            error: false,
        });
    } catch (e) {
        dispatch({
            type: 'GET_ALL_CUSTOMER_COUNT',
            error: true,
        });
    }
};
export const GetAllUser = body => async (dispatch) => {
    try {
        dispatch({
            body,
            type: 'GET_ALL_USER',
            error: false,
        });
    } catch (e) {
        dispatch({
            type: 'GET_ALL_USER',
            error: true,
        });
    }
};
export const AddUser = body => async (dispatch) => {
    try {
        dispatch({
            body,
            type: 'ADD_USER',
            error: false,
        });
    } catch (e) {
        dispatch({
            type: 'ADD_USER',
            error: true,
        });
    }
};
export const GetUser = body => async (dispatch) => {
    try {
        dispatch({
            body,
            type: 'GET_USER',
            error: false,
        });
    } catch (e) {
        dispatch({
            type: 'GET_USER',
            error: true,
        });
    }
};
export const DeleteCustomer = body => async (dispatch) => {
    try {
        dispatch({
            body,
            type: 'DELETE_CUSTOMER',
            error: false,
        });
    } catch (e) {
        dispatch({
            type: 'DELETE_CUSTOMER',
            error: true,
        });
    }
};
export const UpdateUser = body => async (dispatch) => {
    try {
        dispatch({
            body,
            type: 'UPDATE_USER',
            error: false,
        });
    } catch (e) {
        dispatch({
            type: 'UPDATE_USER',
            error: true,
        });
    }
};
export const ChangePassword = body => async (dispatch) => {
    try {
        dispatch({
            body,
            type: 'CHANGE_USER_PASSWORD',
            error: false,
        });
    } catch (e) {
        dispatch({
            type: 'CHANGE_USER_PASSWORD',
            error: true,
        });
    }
};

export const UpdateUserState = () => async (dispatch) => {
    try {
        dispatch({
            type: 'UNSET_USER_STATE',
        });
    } catch (e) {
        dispatch({
            type: 'UNSET_USER_STATE',
            error: true,
            code: '511',
            message: e.toString(),
        });
    }
};
export const UpdateGetUserState = () => async (dispatch) => {
    try {
        dispatch({
            type: 'UNSET_GETUSER_STATE',
        });
    } catch (e) {
        dispatch({
            type: 'UNSET_GETUSER_STATE',
            error: true,
            code: '511',
            message: e.toString(),
        });
    }
};

// Custom Search
export const CustomSearch = body => async (dispatch) => {
    try {
        dispatch({
            body,
            type: 'CUSTOM_SEARCH_USER',
            error: false,
        });
    } catch (e) {
        dispatch({
            type: 'CUSTOM_SEARCH_USER',
            error: true,
        });
    }
}

// Custom Search
export const CustomFilter = body => async (dispatch) => {
    try {
        dispatch({
            body,
            type: 'CUSTOM_FILTER_USER',
            error: false,
        });
    } catch (e) {
        dispatch({
            type: 'CUSTOM_FILTER_USER',
            error: true,
        });
    }
}
