import { takeEvery, call, put } from 'redux-saga/effects';
import axios from 'axios';

function* fetchAllServices(action) {
    try {
        const { data } = yield call(axios.post, `${process.env.REACT_APP_API}/services/${action.body.page}/${action.body.limit}`, { ...action.body }, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('access_token')}`,
            },
        });
        yield put({ type: 'GET_ALL_SERVICEPAGES', services: data });
    } catch (e) {
        yield put({ type: 'SERVICE_ERROR', message: e, error: true });
    }
}

export default function* watchUPDATEMESSAGE() {
    yield takeEvery('GET_ALL_SERVICEPAGE', fetchAllServices);
}
