import { takeEvery, call, put } from 'redux-saga/effects';
import axios from 'axios';

function* fetchAllSettings() {
    try {
        const { data } = yield call(axios.get, `${ process.env.REACT_APP_API }/settings`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('access_token')}`,
            },
        });
        yield put({ type: 'GET_ALL_SETTINGS', settings : data });
    } catch (e) {
        yield put({ type: 'SETTING_ERROR', message: e, error: true });
    }
}

export default function* watchUPDATEMESSAGE() {
    yield takeEvery('GET_ALL_SETTING', fetchAllSettings);
}
