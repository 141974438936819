import { takeEvery, call, put } from 'redux-saga/effects';
import axios from 'axios';

function* updateCurrency(action) {
    try {
        yield call(axios.post, `${ process.env.REACT_APP_API }/currency`, { ...action.body }, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('access_token')}`,
            },
        });
        const currency = yield call(axios.get, `${ process.env.REACT_APP_API }/currency/${ action.body.currencyId }`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('access_token')}`,
            },
        });
        yield put({ type: 'GET_COUNTRYS', currency: currency.data });
    } catch (e) {
        yield put({ type: 'UPDATE_COUNTRYS', error: true });
    }
}

export default function* watchUPDATEMESSAGE() {
    yield takeEvery('UPDATE_COUNTRY', updateCurrency);
}
