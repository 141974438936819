import { takeEvery, call, put } from 'redux-saga/effects';
import axios from 'axios';

function* updateUserType(action) {
    try {
        yield call(axios.put, `${process.env.REACT_APP_API}/userType/${action.body.userTypeId}`, { ...action.body }, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('access_token')}`,
            },
        });
        const { data } = yield call(axios.get, `${process.env.REACT_APP_API}/userType`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('access_token')}`,
            },
        });
        yield put({ type: 'GET_ALL_USERTYPES', userTypes: data });
    } catch (e) {
        yield put({ type: 'UPDATE_USERTYPES', error: true });
    }
}

export default function* watchUPDATEMESSAGE() {
    yield takeEvery('UPDATE_USERTYPE', updateUserType);
}
