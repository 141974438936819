import { takeEvery, call, put } from 'redux-saga/effects';
import axios from 'axios';

function* deleteCustomer(action) {
    try {
        yield call(axios.post, `${process.env.REACT_APP_API}/user/removeCustomer/${action.body.userId}`, {}, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('access_token')}`,
            },
        });
        const user = yield call(axios.get, `${process.env.REACT_APP_API}/user/list/${action.body.userType}/${action.body.page}/${action.body.limit}`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('access_token')}`,
            },
        });
        yield put({ type: 'GET_ALL_USERS', users: user.data });
    } catch (e) {
        yield put({ type: 'DELETE_CUSTOMERS', error: true });
    }
}

export default function* watchUPDATEMESSAGE() {
    yield takeEvery('DELETE_CUSTOMER', deleteCustomer);
}

