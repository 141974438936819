const defaultState = {
    userTypeList: [],
    userTypeResponse: {},
    updateResponse: {},
    errorResponse: {},
    error: false,
};

export default function taxReducer(state = defaultState, action) {
    switch (action.type) {
        case 'GET_ALL_USERTYPES': {
            return {
                ...state,
                error: action.error,
                userTypeList: action.userTypes,
                updateState: true,
            };
        }
        case 'GET_USERTYPES': {
            return {
                ...state,
                error: action.error,
                userTypeResponse: action.userType,
                updateGetState: true,
            };
        }
        case 'ADD_USERTYPES': {
            return {
                ...state,
                error: action.error,
                userTypeResponse: action.userTypeResponse,
            };
        }
        case 'UPDATE_USERTYPES': {
            return {
                ...state,
                error: action.error,
                updateResponse: action.updateResponse,
                updateRoleState: true,
            };
        }
        case 'DELETE_USERTYPES': {
            return {
                ...state,
                error: action.error,
                deleteResponse: action.deleteResponse,
                deleteState: true,
            };
        }
        case 'UNSET_USERTYPE_STATE': {
            return {
                ...state,
                updateState: false,
            };
        }
        case 'UNSET_GETUSERTYPE_STATE': {
            return {
                ...state,
                updateGetState: false,
            };
        }
        case 'USERTYPE_ERROR': {
            return {
                ...state,
                errorResponse: action.message,
            };
        }
        default: {
            return state;
        }
    }
}
