import { takeEvery, call, put } from 'redux-saga/effects';
import axios from 'axios';

function* fetchSpare(actions) {
    try {
        
        const { data }  = yield call(axios.get, `${ process.env.REACT_APP_API }/spares/${ actions.body.sparesId }`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('access_token')}`,
            },
        });
        yield put({ type: 'GET_SPARES', spare: data });
    } catch (e) {
        yield put({ type: 'SPARE_ERROR', message: e, error: true });
    }
}

export default function* watchUPDATEMESSAGE() {
    yield takeEvery('GET_SPARE', fetchSpare);
}
