const defaultState = {
    fileList: [],
    fileResponse: {},
    updateResponse: {},
    errorResponse: {},
    error: false,

};

export default function fileReducer(state = defaultState, action) {
    switch (action.type) {
        case 'GET_ALL_FILES': {
            return {
                ...state,
                error: action.error,
                fileList: action.files,
                updateState: true,
            };
        }
        case 'GET_FILES': {
            return {
                ...state,
                error: action.error,
                fileResponse: action.files,
                updateGetState: true,
            };
        }
        case 'ADD_FILES': {
            return {
                ...state,
                error: action.error,
                fileResponse: action.fileResponse,
            };
        }
        case 'UPDATE_FILES': {
            return {
                ...state,
                error: action.error,
                updateResponse: action.updateResponse,
                updateRoleState: true,
            };
        }
        case 'UNSET_FILE_STATE': {
            return {
                ...state,
                updateState: false,
            };
        }
        case 'UNSET_GETFILE_STATE': {
            return {
                ...state,
                updateGetState: false,
            };
        }
        case 'FILE_ERROR': {
            return {
                ...state,
                errorResponse: action.message,
            };
        }
        default: {
            return state;
        }
    }
}
