import { takeEvery, call, put } from 'redux-saga/effects';
import axios from 'axios';

function* removeScopesFromRole(action)
{
    try
    {
        yield call(axios.delete, `${ process.env.REACT_APP_API }/role/${ action.body.roleId }/scopes/${ action.body.scopeId }`, {
            headers: {
                Authorization: `Bearer ${ localStorage.getItem('access_token') }`,
            },
        });
        const role = yield call(axios.get, `${ process.env.REACT_APP_API }/role/${ action.body.roleId }`, {
            headers: {
                Authorization: `Bearer ${ localStorage.getItem('access_token') }`,
            },
        });
        yield put({ type: 'GET_ROLES', role: role.data });
    } catch (e)
    {
        yield put({ type: 'REMOVE_SCOPE_ROLES', error: true });
    }
}

export default function* watchUPDATEMESSAGE()
{
    yield takeEvery('REMOVE_SCOPE_ROLE', removeScopesFromRole);
}
