import { takeEvery, call, put } from 'redux-saga/effects';
import axios from 'axios';

function* updateReceptionSpareParts(action) {
    try {
        let formData = new FormData();

        const imgs = Object.keys(action.body.images);
        formData.append('sparesName', action.body.sparePartName);
        formData.append('description', action.body.sparePartdescription);
        formData.append('transactionId', action.body.transactionId);
        formData.append('carId', action.body.car);

        imgs.forEach(e => {
            formData.append('file', action.body.images[e]);
        });

        yield call(axios.post, `${process.env.REACT_APP_API}/spares/`, formData, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('access_token')}`,
            },
        });
        const { data } = yield call(axios.post, `${process.env.REACT_APP_API}/transactionHeader/getAllJobCard/1/10`, { ...action.body }, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('access_token')}`,
            },
        });
        yield put({ type: 'GET_ALL_RECEPTION_JOB_CARDS', jobcards: data });
    } catch (e) {
        yield put({ type: 'RECEPTION_JOB_CARD_ERROR', error: true });
    }
}

export default function* watchUPDATEMESSAGE() {
    yield takeEvery('UPDATE_RECEPTION_SPARE_PART', updateReceptionSpareParts);
}