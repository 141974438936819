import GetAllJobs from './GetAllJobs'
import GetAllCancelledJobs from './GetAllCancelledJobs';
import UpdateWorkshopImage from './UpdateWorkshopImage';
import UpdateSparePartList from './UpdateSparePartList';
import UpdateStatus from './UpdateStatus';
import ExtendWork from "./ExtendWork";
import GetJobCards from './GetJobCards';



export default [GetAllJobs, GetAllCancelledJobs, GetJobCards, UpdateWorkshopImage, UpdateSparePartList, UpdateStatus, ExtendWork];
