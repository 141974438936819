import { takeEvery, call, put } from 'redux-saga/effects';
import axios from 'axios';

function* UpdateSettings(action) {
    try {
        yield call(axios.put, `${process.env.REACT_APP_API}/settings`, { ...action.body }, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('access_token')}`,
            },
        });
        const { data } = yield call(axios.get, `${ process.env.REACT_APP_API }/settings`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('access_token')}`,
            },
        });
        yield put({ type: 'GET_ALL_SETTINGS', settings : data });
    } catch (e) {
        yield put({ type: 'UPDATE_SETTINGS', error: true });
    }
}

export default function* watchUPDATEMESSAGE() {
    yield takeEvery('UPDATE_SETTING', UpdateSettings);
}
