const defaultState = {
    jobCardList: [],
    errorResponse: {},
    updateResponse: {},
    error: false,
};

export default function ratingReducer(state = defaultState, action) {
    switch (action.type) {
        case 'GET_ALL_ADMIN_JOB_CARDS': {
            return {
                ...state,
                error: action.error,
                jobCardList: action.jobcards,
                updateState: true,
            };
        }
        case 'GET_TODAY_JOB_CARDS': {
            return {
                ...state,
                error: action.error,
                jobCardList: action.jobcards,
                updateState: true,
            };
        }
        case 'UNSET_GET_ALL_ADMIN_JOB_CARD_STATE': {
            return {
                ...state,
                updateState: false,
            };
        }
        case 'ADMIN_JOB_CARD_ERROR': {
            return {
                ...state,
                errorResponse: action.message,
            };
        }
        default: {
            return state;
        }
    }
}