import { takeEvery, call, put } from 'redux-saga/effects';
import axios from 'axios';

function* updateVehicleModel(action) {
    try {
        yield call(axios.put, `${process.env.REACT_APP_API}/vehicleModel/${action.body.vehicleModelId}`, { ...action.body }, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('access_token')}`,
            },
        });
        const { data } = yield call(axios.post, `${process.env.REACT_APP_API}/vehicleModel/1/10`, { ...action.body }, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('access_token')}`,
            },
        });
        yield put({ type: 'GET_ALL_VEHICLEMODELPAGES', vehicleModels: data });
    } catch (e) {
        yield put({ type: 'UPDATE_VEHICLEMODELS', error: true });
    }
}

export default function* watchUPDATEMESSAGE() {
    yield takeEvery('UPDATE_VEHICLEMODEL', updateVehicleModel);
}
