import { takeEvery, call, put } from 'redux-saga/effects';
import axios from 'axios';

function* fetchAllScopes()
{
    try
    {
        const { data } = yield call(axios.get, `${ process.env.REACT_APP_API }/scope`, {
            headers: {
                Authorization: `Bearer ${ localStorage.getItem('access_token') }`,
            },
        });
        yield put({ type: 'GET_ALL_SCOPES', scopes: data });
    } catch (e)
    {
        yield put({ type: 'GET_ALL_SCOPES', error: true });
    }
}

export default function* watchUPDATEMESSAGE()
{
    yield takeEvery('GET_ALL_SCOPE', fetchAllScopes);
}
