import { takeEvery, call, put } from 'redux-saga/effects';
import axios from 'axios';

function* updateWorkshopImage(action) {
    try {
        let formData = new FormData();
        formData.append('file', action.body.vehicleReportForm);
        
        yield call(axios.post, `${ process.env.REACT_APP_API }/transactionHeader/uploadReport/${action.body.transactionId}`, formData, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('access_token')}`,
            },
        });
        const { data } = yield call(axios.post, `${ process.env.REACT_APP_API }/transactionHeader/getWorkShopJobCard`, { ...action.body }, {
            headers: {
                Authorization: `Bearer ${ localStorage.getItem('access_token') }`,
            },
        });
        yield put({ type: 'GET_ALL_WORKSHOP_JOB_CARDS', jobcards: data });
    } catch (e) {
        yield put({ type: 'WORKSHOP_JOB_CARD_ERROR', error: true });
    }
}

export default function* watchUPDATEMESSAGE() {
    yield takeEvery('UPDATE_WORKSHOP_IMAGE', updateWorkshopImage);
}