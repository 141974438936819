import { takeEvery, call, put } from 'redux-saga/effects';
import axios from 'axios';

function* fetchNews(actions) {
    try {
        const { data } = yield call(axios.get, `${process.env.REACT_APP_API}/news/${actions.body.newsId}`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('access_token')}`,
            },
        });

        yield put({ type: 'GET_NEWSS', newss: data });
    } catch (e) {
        yield put({ type: 'NEWS_ERROR', message: e, error: true });
    }
}

export default function* watchUPDATEMESSAGE() {
    yield takeEvery('GET_NEWS', fetchNews);
}
