import { takeEvery, call, put } from 'redux-saga/effects';
import axios from 'axios';

function* fetchAllJobs(action) {
    try {
        const { data } = yield call(axios.get, `${process.env.REACT_APP_API}/transactionHeader/getAppoinmentJobCard`, { ...action.body }, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('access_token')}`,
            },
        });
        yield put({ type: 'GET_ALL_APPOINMENT_JOB_CARDS', jobcards: data });
    } catch (e) {
        yield put({ type: 'RECEPTION_JOB_CARD_ERROR', message: e, error: true });
    }
}

export default function* watchUPDATEMESSAGE() {
    yield takeEvery('GET_ALL_APPOINMENT_JOB_CARD', fetchAllJobs);
}
