import React, { Component, Fragment } from "react";
import { Redirect } from "react-router-dom";
import { LayoutSplashScreen } from "../../../_metronic";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as MeActions from "../../ReduxStore/Actions/me";


class Logout extends Component {
  componentDidMount() {
    const { MeActions } = this.props;
    this.logout();
    MeActions.GetMeLogout()
  }

  logout() {
    localStorage.removeItem('access_token');
    localStorage.removeItem('demo1-lastLocation');
    localStorage.removeItem('demo1-lastLocation_expiresIn');
    localStorage.removeItem('persist:uDaTa');
    localStorage.removeItem('persist:i18n');
    localStorage.removeItem('persist:build');
  }

  render() {

    return (
      <Fragment>
        <LayoutSplashScreen />
        <Redirect to="/auth" />
      </Fragment>
    )
  }
}


const mapStateToProps = ({ me: { user } }) => ({
  user
});

function mapDispatchToProps(dispatch) {
  return {
    MeActions: bindActionCreators(MeActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Logout);


