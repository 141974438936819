import { takeEvery, call, put } from 'redux-saga/effects';
import axios from 'axios';

function* deleteMake(action) {
    try {
        yield call(axios.delete, `${process.env.REACT_APP_API}/vehicleMake/${action.body.vehicleMakeId}`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('access_token')}`,
            },
        });
        const { data } = yield call(axios.post, `${process.env.REACT_APP_API}/vehicleMake/1/10`, { ...action.body }, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('access_token')}`,
            },
        });

        yield put({ type: 'GET_ALL_VEHICLEMAKEPAGES', vehicleMakes: data });
    } catch (e) {
        yield put({ type: 'DELETE_VEHICLEMAKES', error: true });
    }
}

export default function* watchUPDATEMESSAGE() {
    yield takeEvery('DELETE_VEHICLEMAKE', deleteMake);
}
