import { takeEvery, call, put } from 'redux-saga/effects';
import axios from 'axios';

function* fetchAllVehicleModel() {
    try {
        const { data } = yield call(axios.get, `${process.env.REACT_APP_API}/vehicleModel`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('access_token')}`,
            },
        });
        yield put({ type: 'GET_ALL_VEHICLEMODELS', vehicleModels: data });
    } catch (e) {
        yield put({ type: 'VEHICLEMODEL_ERROR', message: e, error: true });
    }
}

export default function* watchUPDATEMESSAGE() {
    yield takeEvery('GET_ALL_VEHICLEMODEL', fetchAllVehicleModel);
}
