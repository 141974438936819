import { takeEvery, call, put } from 'redux-saga/effects';
import axios from 'axios';

function* deleteModel(action) {
    try {
        yield call(axios.delete, `${process.env.REACT_APP_API}/vehicleModel/${action.body.vehicleModelId}`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('access_token')}`,
            },
        });
        const { data } = yield call(axios.post, `${process.env.REACT_APP_API}/vehicleModel/1/10`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('access_token')}`,
            },
        });

        yield put({ type: 'GET_ALL_VEHICLEMODELPAGES', vehicleModels: data });
    } catch (e) {
        yield put({ type: 'DELETE_VEHICLEMODELS', error: true });
    }
}

export default function* watchUPDATEMESSAGE() {
    yield takeEvery('DELETE_VEHICLEMODEL', deleteModel);
}
