import { takeEvery, call, put } from 'redux-saga/effects';
import axios from 'axios';

function* updateRole(action)
{
    try
    {

        yield call(axios.put, `${ process.env.REACT_APP_API }/role/${ action.body.roleId }`, { ...action.body }, {
            headers: {
                Authorization: `Bearer ${ localStorage.getItem('access_token') }`,
            },
        });
        const role = yield call(axios.get, `${ process.env.REACT_APP_API }/role/${ action.body.roleId }`, {
            headers: {
                Authorization: `Bearer ${ localStorage.getItem('access_token') }`,
            },
        });
        yield put({ type: 'GET_ROLES', role: role.data });
    } catch (e)
    {
        yield put({ type: 'UPDATE_ROLE_NAMES', error: true });
    }
}

export default function* watchUPDATEMESSAGE()
{
    yield takeEvery('UPDATE_ROLE_NAME', updateRole);
}
