import { takeEvery, call, put } from 'redux-saga/effects';
import axios from 'axios';

function* fetchCar(actions) {
    try {
        const { data } = yield call(axios.get, `${process.env.REACT_APP_API}/cars/${actions.body.carsId}`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('access_token')}`,
            },
        });
        yield put({ type: 'GET_CARS', car: data });
    } catch (e) {
        yield put({ type: 'CAR_ERROR', message: e, error: true });
    }
}

export default function* watchUPDATEMESSAGE() {
    yield takeEvery('GET_CAR', fetchCar);
}
