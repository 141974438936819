const defaultState = {
    paymentList: [],
    errorResponse: {},
    updateResponse: {},
    error: false,
};

export default function paymentReducer(state = defaultState, action) {
    switch (action.type) {
        case 'GET_ALL_PAYMENTS': {
            return {
                ...state,
                error: action.error,
                paymentList: action.payments,
                updateState: true,
            };
        }
        case 'UNSET_PAYMENT_STATE': {
            return {
                ...state,
                updateState: false,
            };
        }
        case 'PAYMENT_ERROR': {
            return {
                ...state,
                errorResponse: action.message,
            };
        }
        default: {
            return state;
        }
    }
}