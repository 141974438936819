import { takeEvery, call, put } from 'redux-saga/effects';
import axios from 'axios';

function* changePassword(action) {
    try {
        const { data  } = yield call(axios.post, `${ process.env.REACT_APP_API }/auth/changePassword`, { ...action.body }, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('access_token')}`,
            },
        });
        
        yield put({ type: 'CHANGE_USER_PASSWORDS', passwordResponse: data });
    } catch (e) {
        yield put({ type: 'CHANGE_USER_PASSWORDS', error: true });
    }
}

export default function* watchUPDATEMESSAGE() {
    yield takeEvery('CHANGE_USER_PASSWORD', changePassword);
}
