import { takeEvery, call, put } from 'redux-saga/effects';
import axios from 'axios';

function* updateBookingStatus(action) {
    try {
        let formData = new FormData();
        formData.append('file', action.body.reportFile);
        formData.append('status', action.body.status);
        formData.append('transactionHeader', action.body.transactionHeader);
        formData.append('reportNote', action.body.reportNote);

        yield call(axios.post, `${process.env.REACT_APP_API}/transactionHeader/updateStatus`, formData, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('access_token')}`,
            },
        });
        const { data } = yield call(axios.post, `${process.env.REACT_APP_API}/transactionHeader/allWorkShopJobCard/1/10`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('access_token')}`,
            },
        });
        yield put({ type: 'GET_ALL_WORKSHOP_JOB_CARDS', jobcards: data });
    } catch (e) {
        yield put({ type: 'WORKSHOP_JOB_CARD_ERROR', error: true });
    }
}

export default function* watchUPDATEMESSAGE() {
    yield takeEvery('UPDATE_STATUS', updateBookingStatus);
}