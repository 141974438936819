import { takeEvery, call, put } from 'redux-saga/effects';
import axios from 'axios';

function* updateRole(action) {
    try {
        yield call(axios.put, `${process.env.REACT_APP_API}/role/${action.body.roleId}/scopes`, { ...action.body }, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('access_token')}`,
            },
        });
        const role = yield call(axios.post, `${process.env.REACT_APP_API}/role/1/10`, { ...action.body }, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('access_token')}`,
            },
        });
        yield put({ type: 'GET_ALL_ROLEPAGES', roles: role.data });
    } catch (e) {
        yield put({ type: 'UPDATE_ROLES', error: true });
    }
}

export default function* watchUPDATEMESSAGE() {
    yield takeEvery('UPDATE_ROLE', updateRole);
}
