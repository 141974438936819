import { takeEvery, call, put } from 'redux-saga/effects';
import axios from 'axios';

function* updateLocation(action) {
    try {
        yield call(axios.post, `${ process.env.REACT_APP_API }/location/update`, { ...action.body }, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('access_token')}`,
            },
        });
        const location = yield call(axios.get, `${ process.env.REACT_APP_API }/location/${ action.body.locationId }`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('access_token')}`,
            },
        });
        yield put({ type: 'GET_LOCATIONS', location: location.data });
    } catch (e) {
        yield put({ type: 'UPDATE_LOCATIONS', error: true });
    }
}

export default function* watchUPDATEMESSAGE() {
    yield takeEvery('UPDATE_LOCATION', updateLocation);
}
