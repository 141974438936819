const defaultState = {
    vehicleModelList: [],
    vehicleModelResponse: {},
    updateResponse: {},
    errorResponse: {},
    error: false,
};

export default function vehicleModelReducer(state = defaultState, action) {
    switch (action.type) {
        case 'GET_ALL_VEHICLEMODELS': {
            return {
                ...state,
                error: action.error,
                vehicleModelList: action.vehicleModels,
                updateState: true,
            };
        }
        case 'GET_ALL_VEHICLEMODELPAGES': {
            return {
                ...state,
                error: action.error,
                vehicleModelList: action.vehicleModels,
                updateState: true,
            };
        }
        case 'GET_VEHICLEMODELS': {
            return {
                ...state,
                error: action.error,
                vehicleModelResponse: action.vehicleModel,
                updateGetState: true,
            };
        }
        case 'ADD_VEHICLEMODELS': {
            return {
                ...state,
                error: action.error,
                vehicleModelResponse: action.vehicleModelResponse,
            };
        }
        case 'UPDATE_VEHICLEMODELS': {
            return {
                ...state,
                error: action.error,
                updateResponse: action.updateResponse,
                updateRoleState: true,
            };
        }
        case 'DELETE_VEHICLEMODELS': {
            return {
                ...state,
                error: action.error,
                deleteResponse: action.deleteResponse,
                deleteState: true,
            };
        }
        case 'UNSET_VEHICLEMODEL_STATE': {
            return {
                ...state,
                updateState: false,
            };
        }
        case 'UNSET_GETVEHICLEMODEL_STATE': {
            return {
                ...state,
                updateGetState: false,
            };
        }
        case 'VEHICLEMODEL_ERROR': {
            return {
                ...state,
                errorResponse: action.message,
            };
        }
        default: {
            return state;
        }
    }
}
