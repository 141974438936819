import { takeEvery, call, put } from 'redux-saga/effects';
import axios from 'axios';

function* deleteUserType(action) {
    try {
        yield call(axios.delete, `${process.env.REACT_APP_API}/userType/${action.body.userTypeId}`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('access_token')}`,
            },
        });
        const { data } = yield call(axios.get, `${process.env.REACT_APP_API}/userType`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('access_token')}`,
            },
        });

        yield put({ type: 'GET_ALL_USERTYPES', userTypes: data });
    } catch (e) {
        yield put({ type: 'DELETE_USERTYPE', error: true });
    }
}

export default function* watchUPDATEMESSAGE() {
    yield takeEvery('DELETE_USERTYPE', deleteUserType);
}
