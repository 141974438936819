import GetAllUser from './GetAllUser';
import GetUser from './GetUser';
import PostUser from './PostUser';
import UpdateUser from './UpdateUser';
import CustomerCount from './CustomerCount';
import ChangePassword from './ChangePassword';
import RemoveCustomer from './RemoveCustomer';
import CustomSearch from './CustomSearch';
import CustomFilter from './CustomFilter';

export default [CustomerCount, CustomFilter, CustomSearch, GetAllUser, PostUser, GetUser, UpdateUser, ChangePassword, RemoveCustomer];
