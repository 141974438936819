import { takeEvery, call, put } from 'redux-saga/effects';
import axios from 'axios';

function* fetchAllVehicleLevel() {
    try {
        const { data } = yield call(axios.get, `${process.env.REACT_APP_API}/vehicleLevels`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('access_token')}`,
            },
        });
        yield put({ type: 'GET_ALL_VEHICLELEVELS', vehicleLevels: data });
    } catch (e) {
        yield put({ type: 'VEHICLELEVEL_ERROR', message: e, error: true });
    }
}

export default function* watchUPDATEMESSAGE() {
    yield takeEvery('GET_ALL_VEHICLELEVEL', fetchAllVehicleLevel);
}
