import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { toAbsoluteUrl } from "../../../_metronic";
import "../../../_metronic/_assets/sass/pages/login/login-3.scss";
import Login from "./Login";
// import Registration from "./Registration";
// import ForgotPassword from "./ForgotPassword";

export default function AuthPage()
{
  return (
    <>
      <div className="">
        <div
          id="kt_login"
          className=""
        >
          <div className=""
            style={{
              backgroundImage: `url(${ toAbsoluteUrl("/media/bg/bg-3.jpg") })`
            }}
          >
            <div className="">
              <Switch>
                <Route path="/auth/login" component={Login} />
                <Redirect from="/auth" exact={true} to="/auth/login" />
                <Redirect to="/auth/login" />
              </Switch>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
