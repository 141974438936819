const defaultState = {
    spareList: [],
    spareResponse: {},
    updateResponse: {},
    errorResponse: {},
    error: false,
    sliderType: {},
};

export default function spareReducer(state = defaultState, action) {
    switch (action.type) {
        case 'GET_ALL_SPARES': {
            return {
                ...state,
                error: action.error,
                spareList: action.spares,
                updateState: true,
            };
        }
        case 'GET_SPARES': {
            return {
                ...state,
                error: action.error,
                spare: action.spare,
                updateGetState: true,
            };
        }
        case 'ADD_SPARES': {
            return {
                ...state,
                error: action.error,
                spareResponse: action.spareResponse,
            };
        }
        case 'UPDATE_SPARES': {
            return {
                ...state,
                error: action.error,
                updateResponse: action.updateResponse,
                updateSpareState: true,
            };
        }
        case 'UNSET_SPARE_STATE': {
            return {
                ...state,
                updateState: false,
            };
        }
        case 'UNSET_GETSPARE_STATE': {
            return {
                ...state,
                updateGetState: false,
            };
        }
        case 'SPARE_ERROR': {
            return {
                ...state,
                errorResponse: action.message,
            };
        }
        default: {
            return state;
        }
    }
}
