export const getMe = () => async (dispatch) => {
  try {
      dispatch({
          type: 'GET_ME',
      });
  } catch (e) {
      dispatch({
          type: 'GET_ME',
          error: true,
      });
  }
};
export const GetMeLogout = () => async (dispatch) => {
  try {
      dispatch({
          type: 'GET_ME_LOGOUT',
      });
  } catch (e) {
      dispatch({
          type: 'GET_ME_LOGOUT',
          error: true,
      });
  }
};
export const UpdateGetMeState = () => async (dispatch) => {
    try {
        dispatch({
            type: 'UNSET_GET_ME_STATE',
        });
    } catch (e) {
        dispatch({
            type: 'UNSET_GET_ME_STATE',
            error: true,
        });
    }
  };