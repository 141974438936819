import { takeEvery, call, put } from 'redux-saga/effects';
import axios from 'axios';

function* fetchSlider(actions) {
    try {
        const { data } = yield call(axios.get, `${ process.env.REACT_APP_API }/slider/${actions.body.sliderId}`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('access_token')}`,
            },
        });
        
        yield put({ type: 'GET_SLIDERS', sliders: data });
    } catch (e) {
        yield put({ type: 'SLIDER_ERROR', message: e, error: true });
    }
}

export default function* watchUPDATEMESSAGE() {
    yield takeEvery('GET_SLIDER', fetchSlider);
}
